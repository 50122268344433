const en = {
  common: {
    clone: 'Clone',
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    generateTag: 'Generate label',
    mustSelectARow: 'Must select a row',
    back: 'Back',
    openCompartiment: 'Open compartiment',
    next: 'Next',
    previous: 'Previous',
    print: 'Print',
    finish: 'Finish',
  },

  app: {
    title: 'Clique Retire - IO',
  },

  entities: {
    customer: {
      name: 'customer',
      label: 'Customers',
      menu: 'Customers',
      exporterFileName: 'customer_export',
      list: {
        menu: 'Customers',
        title: 'Customers',
      },
      create: {
        success: 'Customer saved successfully',
      },
      update: {
        success: 'Customer saved successfully',
      },
      destroy: {
        success: 'Customer deleted successfully',
      },
      destroyAll: {
        success: 'Customer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        cellphone: 'Cellphone',
        email: 'E-mail',
        company: 'Company',
        buildingLevel2: 'Place',
        photo: 'Photo',
        pcd: 'Person with disability',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        documentNumber: 'Document Number',
      },
      enumerators: {},
      new: {
        title: 'New Customer',
      },
      view: {
        title: 'View Customer',
      },
      importer: {
        title: 'Import Customers',
        fileName: 'customer_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    product: {
      name: 'product',
      label: 'Products',
      menu: 'Products',
      exporterFileName: 'product_export',
      list: {
        menu: 'Products',
        title: 'Products',
      },
      create: {
        success: 'Product saved successfully',
      },
      update: {
        success: 'Product saved successfully',
      },
      destroy: {
        success: 'Product deleted successfully',
      },
      destroyAll: {
        success: 'Product(s) deleted successfully',
      },
      edit: {
        title: 'Edit Product',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        unitPriceRange: 'Unit Price',
        unitPrice: 'Unit Price',
        photos: 'Photos',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Product',
      },
      view: {
        title: 'View Product',
      },
      importer: {
        title: 'Import Products',
        fileName: 'product_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    order: {
      name: 'order',
      label: 'Orders',
      menu: 'Orders',
      exporterFileName: 'order_export',
      list: {
        menu: 'Orders',
        title: 'Orders',
      },
      create: {
        success: 'Order saved successfully',
      },
      update: {
        success: 'Order saved successfully',
      },
      updateOverdue: {
        title: 'Order overdue update',
        success: 'Order overdue date updated successfully',
        error: 'Order overdue error on update',
      },
      destroy: {
        success: 'Order canceled successfully',
      },
      destroyAll: {
        success: 'Order(s) canceled(s) successfully',
      },
      generateTag: {
        success: 'Tags generated successfully',
      },
      edit: {
        title: 'Edit Order',
      },
      fields: {
        id: 'Id',
        trackingCode: 'Tracking Code',
        referenceNumber: 'Reference Number',
        shipper: 'Shipper',
        company: 'Company/Subsidiary',
        carrier: 'Carrier',
        products: 'Products',
        ebox: 'Ebox',
        state: 'State (UF)',
        cluster: 'Cluster',
        notes: 'Notes',
        toCustomer: 'To Customer',
        accessCode: 'AccessCode',
        attachments: 'Attachments',
        delivered: 'Delivered',
        fromCustomer: 'From Customer',
        compartiment: 'Compartiment',
        compartimentNumber: 'Compartment number',
        inboundAtRange: 'Inbound At',
        inboundAt: 'Inbound At',
        inboundBy: 'Inbound By',
        storedAtRange: 'Stored At',
        storedAt: 'Stored At',
        storedBy: 'StoredBy',
        collectedAtRange: 'Collected At',
        collectedAt: 'Collected At',
        collectedBy: 'Collected By',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        type: 'Type',
        status: 'Status',
        overdueDate: 'Overdue Date',
        compartimentSize: 'Compartiment Size',
        customerName: 'Customer Name',
        customerPhone: 'Customer Phone',
        customerEmail: 'Customer e-mail',
        eboxCode: 'Locker Code',
        notBeforeFirst: 'Not store before at',
        bookOrder: 'Book compartiment',
        invoice: {
          series: 'Invoice series',
          number: 'Invoice number',
          key: 'Invoice Key',
          totalValue: 'Invoice total value',
          productsValue: 'Invoice products value',
          cfop: 'Invoice CFOP',
          date: 'Invoice Date',
          insured: 'Insured'
        }
      },
      enumerators: {
        type: {
          DELIVERY: 'Delivery',
          RETURN: 'Return',
          STORAGE: 'Self-storage',
          SEND: 'Home Delivery',
          SMARTDROP: 'SmartDrop'
        },
        status: {
          CREATED: 'Created',
          INBOUND: 'Inbound',
          STORED: 'Stored',
          COURIER_STORED: 'Courier - Stored',
          CUSTOMER_STORED: 'Stored by Customer',
          CUSTOMER_TAKEN: 'Customer - Taken',
          EXPIRED: 'Expired',
          EXPIRED_TAKEN: 'Expirados Coletados',
          COURIER_TAKEN: 'Taken by Operator',
          CANCELED: 'Canceled',
          CUSTOMER_SEND: 'Customer - Stored',
          CUSTOMER_TAKEN_AND_WAITING_FOR_RELEASE: 'Customer - Taken and waiting for release',
          FAILED_DELIVERY: 'Failed delivery',
        },
        code: {
          EXPIRED: 'Expired',
          MAINTENANCE: 'Maintenance',
          COURIER: 'Operator - Taken',
          CUSTOMER_TAKEN: 'Customer - Taken',
          CUSTOMER_SEND: 'Customer - Stored',
          CUSTOMER_STORAGE: 'Customer - Stored',
          COURIER_TAKEN: 'Courier Taken',
          CUSTOMER_RETURN: 'Return by User'
        },
      },
      new: {
        title: 'New Order',
      },
      view: {
        title: 'View Order',
      },
      importer: {
        title: 'Import Orders',
        fileName: 'order_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
      export: {
        exportMessageErroNotFilterData: 'Select a creation date range before exporting',
      },
      filter: {
        dataPickerLimit: 'Select a period of 31 days',
      },
      tracking: {
        buttonTracking: 'View Tracking details',
        titleTrackingModal: 'Tracking details for order',
      }
    },

    company: {
      name: 'company',
      label: 'Companies',
      menu: 'Companies',
      exporterFileName: 'company_export',
      list: {
        menu: 'Companies',
        title: 'Companies',
      },
      create: {
        success: 'Company saved successfully',
      },
      update: {
        success: 'Company saved successfully',
      },
      destroy: {
        success: 'Company deleted successfully',
      },
      destroyAll: {
        success: 'Company(s) deleted successfully',
      },
      edit: {
        title: 'Edit Company',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        logo: 'Logo',
        type: 'Type',
        externalId: 'External ID',
        allowCollectExpiredOrder: 'Allow expired collection',
        group: 'Group Lockers',
        distributionLogistic: 'Allow Distribution Logistic',
        attachments: 'Attachments',
        parent: 'Parent',
        building: 'Building',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        overdueData: 'Days overdue delivery'
      },
      enumerators: {
        type: {
          shipper: 'Shipper',
          carrier: 'Carrier',
          building: 'Building',
        },
      },
      new: {
        title: 'New Company',
      },
      view: {
        title: 'View Company',
      },
      importer: {
        title: 'Import Companies',
        fileName: 'company_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    payment: {
      name: 'Payment',
      label: 'Payments',
      menu: 'Payments',
      exporterFileName: 'payment_export',
      list: {
        menu: 'Payments',
        title: 'Payments',
      },
      create: {
        success: 'Payment saved successfully',
      },
      update: {
        success: 'Payment saved successfully',
      },
      destroy: {
        success: 'Payment deleted successfully',
      },
      destroyAll: {
        success: 'Payment(s) deleted successfully',
      },
      edit: {
        title: 'Edit Payment',
      },
      fields: {
        id: 'Id',
        company: 'Company',
        ebox: 'Ebox',
        cluster: 'Cluster',
        compartiment: 'Compartiment',
        compartimentNumber: 'Compartment number',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        status: 'Status',
        overdueDate: 'Expiration Date',
        value: 'Value',
        gateway: 'Gateway',
        orderId: 'Order',
        trackingCode: 'Tracking Code',
        compartimentSize: 'Compartiment Size',
        orderTypes: 'Available operations',
        smsGateway: 'SMS Provider'
      },
      enumerators: {
        status: {
          PENDING: 'Pending',
          APPROVED: 'Approved',
          DECLINED: 'Declined',
        },
      },
      new: {
        title: 'New Payment',
      },
      view: {
        title: 'View Payment',
      },
      importer: {
        title: 'Import Payments',
        fileName: 'payment_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    role: {
      name: 'role',
      label: 'Roles',
      menu: 'Roles',
      exporterFileName: 'role_export',
      list: {
        menu: 'Roles',
        title: 'Roles',
      },
      create: {
        success: 'Role saved successfully',
      },
      update: {
        success: 'Role saved successfully',
      },
      destroy: {
        success: 'Role deleted successfully',
      },
      destroyAll: {
        success: 'Role(s) deleted successfully',
      },
      edit: {
        title: 'Edit Role',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        company: 'Company',
        permissions: 'Permissions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      new: {
        title: 'New Role',
      },
      view: {
        title: 'View Role',
      },
      importer: {
        title: 'Import Roles',
        fileName: 'role_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    ebox: {
      name: 'ebox',
      label: 'E-BOX',
      menu: 'E-BOX',
      exporterFileName: 'ebox_export',
      list: {
        menu: 'E-BOX',
        title: 'E-BOX',
      },
      create: {
        success: 'E-BOX saved successfully',
      },
      update: {
        success: 'E-BOX saved successfully',
      },
      destroy: {
        success: 'E-BOX deleted successfully',
      },
      destroyAll: {
        success: 'E-BOX(s) deleted successfully',
      },
      edit: {
        title: 'Edit E-BOX',
      },
      clone: {
        title: 'Clone E-BOX'
      },
      generateConfig: {
        title: 'Generate configuration file',
        success: 'Configuration generated successfully',
        error: 'Error generating configuration'
      },
      generateCompartiments: {
        title: 'Generate compartiments',
        submit: 'Generate',
        hint: 'This action will delete all compartiments and generate new ones',
      },
      settings: {
        title: 'Settings',
        reboot: {
          title: 'Reboot E-BOX',
          success: 'Reboot request sent successfully',
          error: 'Error sending reboot request'
        },
        close_app: {
          title: 'Restart App',
          success: 'Restart request sent successfully',
          error: 'Error sending restart request'
        },
        force_sync: {
          title: 'Sync E-BOX',
          success: 'Sync request sent successfully',
          error: 'Error sending sync request'
        },
      },
      fields: {
        id: 'Id',
        name: 'Name',
        code: 'Code',
        images: 'Images',
        enable: 'Enable',
        status: 'Status',
        street: 'Street',
        number: 'Number',
        complement: 'Complement',
        neighborhood: 'Neighborhood',
        city: 'City',
        company: 'Company',
        terminalId: 'Device Id',
        eboxModule: 'Modules',
        startNumber: 'Start number',
        boardNumber: 'Board number',
        state: 'State',
        postalCode: 'PostalCode',
        coordinates: 'Coordinates',
        warehouse: 'Warehouse',
        building: 'Construction',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        lastHeartBeat: 'Last heartbeat',
        osVersion: 'Version of OS',
        shared: 'Shared',
        workingHours: 'Working Hours',
        application: 'App',
        applicationVersion: 'App version',
      },
      enumerators: {
        status: {
          online: 'Online',
          offline: 'Offline',
        },
        osApplication: {
          windows: 'Windows',
          linux: 'Linux',
          mac: 'Mac',
        },
      },
      new: {
        title: 'New E-BOX',
      },
      view: {
        title: 'View E-BOX',
      },
      importer: {
        title: 'Import E-BOX',
        fileName: 'ebox_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    address: {
      name: 'Delivery Address',
      label: 'Address',
      menu: 'Address',
      exporterFileName: 'address_export',
      list: {
        menu: 'Address',
        title: 'Address',
      },
      create: {
        success: 'Address saved successfully',
      },
      update: {
        success: 'Address saved successfully',
      },
      destroy: {
        success: 'Address deleted successfully',
      },
      destroyAll: {
        success: 'Address deleted successfully',
      },
      edit: {
        title: 'Edit Address',
      },
      fields: {
        id: 'Id',
        street: 'Street',
        number: 'Number',
        additional: 'Additional',
        reference: 'Reference',
        neighborhood: 'Neighborhood',
        city: 'City',
        state: 'State',
        postalCode: 'PostalCode',
        country: 'Country',
        coordinates: 'Coordinates',
        company: 'Company',
        createdAt: 'Created at',
        updatedAt: 'Updated at'
      },
      new: {
        title: 'New Address',
      },
      view: {
        title: 'View Address',
      },
      importer: {
        title: 'Import Address',
        fileName: 'address_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    cluster: {
      name: 'Cluster',
      label: 'Cluster',
      menu: 'Cluster',
      exporterFileName: 'Cluster_export',
      list: {
        menu: 'Cluster',
        title: 'Cluster',
      },
      create: {
        success: 'Cluster saved successfully',
      },
      update: {
        success: 'Cluster saved successfully',
      },
      destroy: {
        success: 'Cluster deleted successfully',
      },
      destroyAll: {
        success: 'Cluster(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cluster',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        sequence: 'Compartiments Sequence Order',
        enable: 'Enable',
        clusterEbox: 'Cluster eBoxes',
        company: 'Company',
        operationType: 'Operation Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        sequence: {
          'A-Z': 'A-Z',
          '1-9999': '1-9999',
        },
        operationType: {
          RANGE: 'RANGE',
          HOUR: 'HOUR',
        },
      },
      new: {
        title: 'New Cluster',
      },
      view: {
        title: 'View Cluster',
      },
      importer: {
        title: 'Import Cluster',
        fileName: 'cluster_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
      compartiment: {
        title: 'Compartiments',
        size: 'Size',
        available: 'Available',
        damaged: 'Damaged',
        occupied: 'Occupied',
        booked: 'Booked',
        total: 'Total'
      }
    },

    clusterEbox: {
      name: 'ClusterEbox',
      label: 'Cluster eBox',
      menu: 'Cluster - e-Box',
      exporterFileName: 'ClusterEbox_export',
      list: {
        menu: 'ClusterEbox',
        title: 'ClusterEbox',
      },
      create: {
        success: 'Cluster Ebox saved successfully',
      },
      update: {
        success: 'Cluster Ebox saved successfully',
      },
      destroy: {
        success: 'Cluster Ebox deleted successfully',
      },
      destroyAll: {
        success: 'Cluster Ebox(s) deleted successfully',
      },
      edit: {
        title: 'Edit Cluster Ebox',
      },
      fields: {
        id: 'Id',
        cluster: 'Cluster',
        clusterName: 'Cluster Name',
        ebox: 'e-Box',
        eboxCode: 'e-Box Code',
        sequenceCompartiments: 'Compartiments Sequence',
        clusterPosition: 'Cluster Position',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        sequenceCompartiments: {
          'RL-TB': 'RL-TB',
          'RL-BT': 'RL-BT',
          'LR-TB': 'LR-TB',
          'LR-BT': 'LR-BT',
        },
      },
      new: {
        title: 'New Cluster Ebox',
      },
      view: {
        title: 'View Cluster Ebox',
      },
      importer: {
        title: 'Import Cluster Ebox',
        fileName: 'clusterEbox_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    eboxModule: {
      name: 'e-Box Modules',
      label: 'e-Box Modules',
      menu: 'e-Box Modules ',
      exporterFileName: 'EboxModule_export',
      list: {
        menu: 'e-Box Modules',
        title: 'e-Box Modules',
      },
      create: {
        success: 'Module saved successfully',
      },
      update: {
        success: 'Module saved successfully',
      },
      destroy: {
        success: 'Module deleted successfully',
      },
      destroyAll: {
        success: 'Module(s) deleted successfully',
      },
      edit: {
        title: 'Edit Module',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        code: 'Code',
        enable: 'Enable',
        eboxModuleCompartiment: 'Compartiments',
        images: 'Images',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        defaultBoardNumber: 'Default Board Number'
      },
      new: {
        title: 'New Module',
      },
      view: {
        title: 'View Module',
      },
      importer: {
        title: 'Import Module',
        fileName: 'eboxModule_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    eboxModuleCompartiment: {
      name: 'Compartiments Module',
      label: 'Compartiments Module',
      menu: 'Compartiments Module',
      exporterFileName: 'EboxModuleCompartiment_export',
      list: {
        menu: 'Compartiments Module',
        title: 'Compartiments Module',
      },
      create: {
        success: 'Compartiment saved successfully',
      },
      update: {
        success: 'Compartiment saved successfully',
      },
      destroy: {
        success: 'Compartiment deleted successfully',
      },
      destroyAll: {
        success: 'Compartiment(s) deleted successfully',
      },
      edit: {
        title: 'Edit Compartiment',
      },
      fields: {
        id: 'Id',
        staticNumber: 'Static Number',
        eboxModule: 'e-Box Module',
        compartimentLayout: 'Layout',
        pcd: 'PCD',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        priority: 'Priority'
      },
      new: {
        title: 'New Compartiment',
      },
      view: {
        title: 'View Compartiment',
      },
      importer: {
        title: 'Import Compartiment',
        fileName: 'eboxModuleCompartiment_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    compartimentLayout: {
      name: 'Compartiment Layout',
      label: 'Compartiment Layout',
      menu: 'Compartiment Layout',
      exporterFileName: 'CompartimentLayout_export',
      list: {
        menu: 'Compartiment Layout',
        title: 'Compartiment Layout',
      },
      create: {
        success: 'Layout saved successfully',
      },
      update: {
        success: 'Layout saved successfully',
      },
      destroy: {
        success: 'Layout deleted successfully',
      },
      destroyAll: {
        success: 'Layout(s) deleted successfully',
      },
      edit: {
        title: 'Edit Layout',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        code: 'Code',
        width: 'Width',
        height: 'Height',
        depth: 'Depth',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      new: {
        title: 'New Layout',
      },
      view: {
        title: 'View Layout',
      },
      importer: {
        title: 'Import Layout',
        fileName: 'compartimentLayout_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    group: {
      name: 'group',
      label: 'Groups',
      menu: 'Groups',
      exporterFileName: 'group_export',
      list: {
        menu: 'Groups',
        title: 'Groups',
      },
      create: {
        success: 'Group saved successfully',
      },
      update: {
        success: 'Group saved successfully',
      },
      destroy: {
        success: 'Group deleted successfully',
      },
      destroyAll: {
        success: 'Group(s) deleted successfully',
      },
      edit: {
        title: 'Edit Group',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        ebox: 'E-BOX',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Group',
      },
      view: {
        title: 'View Group',
      },
      importer: {
        title: 'Import Groups',
        fileName: 'group_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    packing: {
      name: 'packing',
      label: 'Packings',
      menu: 'Packings',
      exporterFileName: 'packing_export',
      list: {
        menu: 'Packings',
        title: 'Packings',
      },
      create: {
        success: 'Packing saved successfully',
      },
      update: {
        success: 'Packing saved successfully',
      },
      destroy: {
        success: 'Packing deleted successfully',
      },
      destroyAll: {
        success: 'Packing(s) deleted successfully',
      },
      finish: {
        success: 'Packing finished successfully',
      },
      edit: {
        title: 'Edit Packing',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        orders: 'Orders',
        eboxes: 'Lockers',
        picking: 'Picking',
        operator: 'Operator',
        warehouse: 'Warehouse',
        trackingCode: 'Order Tracking Code',
        ebox: 'Locker',
        finishedAt: 'Finished At',
        finishedBy: 'Finished By',
        notes: 'Notes',
        company: 'Company',
        totalOrders: 'Total Orders',
        packingOrders: 'Packing Orders',
        createdAt: 'Created at',
        createdBy: 'Created by',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        picking: {
          return: 'Return',
          'collect/delivery': 'Collect and Delivery',
        }
      },
      new: {
        title: 'New Packing',
      },
      view: {
        title: 'View Packing',
      },
      importer: {
        title: 'Import Packings',
        fileName: 'packing_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    compartiment: {
      name: 'compartiment',
      label: 'Compartiments',
      menu: 'Compartiments',
      exporterFileName: 'compartiment_export',
      list: {
        menu: 'Compartiments',
        title: 'Compartiments',
      },
      create: {
        success: 'Compartiment saved successfully',
      },
      update: {
        success: 'Compartiment saved successfully',
      },
      destroy: {
        success: 'Compartiment deleted successfully',
      },
      destroyAll: {
        success: 'Compartiment(s) deleted successfully',
      },
      lock: {
        damage: {
          title: 'Lock',
          success: 'Compartiment locked successfully'
        },
        available: {
          title: 'Release',
          success: 'Compartiment released successfully'
        }
      },
      edit: {
        title: 'Edit Compartiment',
      },
      fields: {
        id: 'Id',
        numberRange: 'Number',
        number: 'Number',
        boardNumberRange: 'Board Number',
        numberInColumn: 'Number in column',
        boardNumber: 'Board Number',
        comNumber: 'COM Board Name',
        usageType: 'Type of Use',
        size: 'Size',
        ebox: 'E-BOX',
        status: 'Status',
        pcd: 'Person with disability',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        width: 'Width',
        height: 'Height',
        depth: 'Depth',
      },
      enumerators: {
        status: {
          AVAILABLE: 'AVAILABLE',
          OCCUPIED: 'OCCUPIED',
          DAMAGE: 'DAMAGE',
          DIRTY: 'DIRTY',
          BOOKED: 'BOOKED'
        },
        usageType: {
          DELIVERY: 'DELIVERY',
          LAUNDRY: 'LAUNDRY',
          FOOD: 'FOOD',
          DROP: 'DROP',
        },
      },
      new: {
        title: 'New Compartiment',
      },
      view: {
        title: 'View Compartiment',
      },
      importer: {
        title: 'Import Compartiments',
        fileName: 'compartiment_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    webhook: {
      name: 'webhook',
      label: 'Webhooks',
      menu: 'Webhooks',
      exporterFileName: 'webhook_export',
      list: {
        menu: 'Webhooks',
        title: 'Webhooks',
      },
      create: {
        success: 'Webhook saved successfully',
      },
      update: {
        success: 'Webhook saved successfully',
      },
      destroy: {
        success: 'Webhook deleted successfully',
      },
      destroyAll: {
        success: 'Webhook(s) deleted successfully',
      },
      edit: {
        title: 'Edit Webhook',
      },
      fields: {
        id: 'Id',
        url: 'Url',
        company: 'Company',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Webhook',
      },
      view: {
        title: 'View Webhook',
      },
      importer: {
        title: 'Import Webhooks',
        fileName: 'webhook_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    ccm: {
      name: 'ccm',
      label: 'CCM',
      menu: 'CCM',
      exporterFileName: 'ccm_export',
      list: {
        menu: 'CCM',
        title: 'CCM',
      },
      create: {
        success: 'Ccm saved successfully',
      },
      update: {
        success: 'Ccm saved successfully',
      },
      destroy: {
        success: 'Ccm deleted successfully',
      },
      destroyAll: {
        success: 'Ccm(s) deleted successfully',
      },
      edit: {
        title: 'Edit Ccm',
      },
      fields: {
        id: 'Id',
        type: 'Type',
        subType: 'Order Type',
        cluster: 'Cluster',
        content: 'Content',
        subject: 'Subject',
        trigger: 'Trigger',
        company: 'Company',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        type: {
          sms: 'SMS',
          email: 'Email',
          push: 'Push',
        },
        subType: {
          delivery: 'Delivery',
          return: 'Return',
          storage: 'Self-storage',
          send: 'Home Delivery',
        },
        trigger: {
          CREATED: 'Order created',
          STORED: 'Stored',
          EXPIRED: 'Expired',
          INBOUND: 'Inbound',
          IN_TRANSIT_TO_STORE: 'In transit to store',
          EXPIRED_TAKEN: 'Expired Collected',
          EXPIRED_INBOUND: 'Expired Inbound',
          EXPIRED_RETURNED: 'Expired Returned',
          COURIER_TAKEN: 'Collected by Operator',
          COURIER_INBOUND: 'Inbound by Operator',
          COURIER_RETURNED: 'Returned by Operator',
          CUSTOMER_TAKEN: 'Customer Taken',
          PRE_EXPIRED: 'Upcomming Expired',
          CANCELED: 'Canceled'
        },
      },
      new: {
        title: 'New Ccm',
      },
      view: {
        title: 'View Ccm',
      },
      importer: {
        title: 'Import CCM',
        fileName: 'ccm_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    qrcodeTemplate: {
      name: 'qrcodeTemplate',
      label: 'Qrcode Template',
      menu: 'Qrcode Template',
      exporterFileName: 'qrcodeTemplate_export',
      list: {
        menu: 'Qrcode Template',
        title: 'Qrcode Template',
      },
      create: {
        success: 'Qrcode Template saved successfully',
      },
      update: {
        success: 'Qrcode Template saved successfully',
      },
      destroy: {
        success: 'Qrcode Template deleted successfully',
      },
      destroyAll: {
        success: 'Qrcode Template(s) deleted successfully',
      },
      edit: {
        title: 'Edit Qrcode Template',
      },
      fields: {
        id: 'Id',
        type: 'Type',
        hbsContent: 'Handlebars Content',
        company: 'Company',
        enable: 'Enable',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        type: {
          STORED: 'Stored',
          CUSTOMER_TAKEN: 'Customer Taken',
          CUSTOMER_SEND: 'Customer Send',
          EXPIRED: 'Expired',
        }
      },
      new: {
        title: 'New QrcodeTemplate',
      },
      view: {
        title: 'View QrcodeTemplate',
      },
      importer: {
        title: 'Import QrcodeTemplate',
        fileName: 'qrcodeTemplate_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    warehouse: {
      name: 'warehouse',
      label: 'Warehouses',
      menu: 'Warehouses',
      exporterFileName: 'warehouse_export',
      list: {
        menu: 'Warehouses',
        title: 'Warehouses',
      },
      create: {
        success: 'Warehouse saved successfully',
      },
      update: {
        success: 'Warehouse saved successfully',
      },
      destroy: {
        success: 'Warehouse deleted successfully',
      },
      destroyAll: {
        success: 'Warehouse(s) deleted successfully',
      },
      edit: {
        title: 'Edit Warehouse',
      },
      fields: {
        id: 'Id',
        company: 'Company',
        street: 'Street',
        name: 'Name',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Warehouse',
      },
      view: {
        title: 'View Warehouse',
      },
      importer: {
        title: 'Import Warehouses',
        fileName: 'warehouse_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    building: {
      name: 'building',
      label: 'Buildings',
      menu: 'Buildings',
      exporterFileName: 'building_export',
      list: {
        menu: 'Buildings',
        title: 'Buildings',
      },
      create: {
        success: 'Building saved successfully',
      },
      update: {
        success: 'Building saved successfully',
      },
      destroy: {
        success: 'Building deleted successfully',
      },
      destroyAll: {
        success: 'Building(s) deleted successfully',
      },
      edit: {
        title: 'Edit Building',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        level1: 'Level1',
        level2: 'Level2',
        externalId: 'External Id',
        customer: {
          phone: 'Customer - Phone',
          name: 'Customer - Name',
          email: 'Customer - Email'
        },
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        company: 'Company',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Building',
      },
      view: {
        title: 'View Building',
      },
      importer: {
        title: 'Import Buildings',
        fileName: 'building_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    buildingLevel1: {
      name: 'buildingLevel1',
      label: 'BuildingLevel1',
      menu: 'BuildingLevel1',
      exporterFileName: 'buildingLevel1_export',
      list: {
        menu: 'BuildingLevel1',
        title: 'BuildingLevel1',
      },
      create: {
        success: 'BuildingLevel1 saved successfully',
      },
      update: {
        success: 'BuildingLevel1 saved successfully',
      },
      destroy: {
        success: 'BuildingLevel1 deleted successfully',
      },
      destroyAll: {
        success: 'BuildingLevel1(s) deleted successfully',
      },
      edit: {
        title: 'Edit BuildingLevel1',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        company: 'Company',
        building: 'Construction',
        level2: 'Level2',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New BuildingLevel1',
      },
      view: {
        title: 'View BuildingLevel1',
      },
      importer: {
        title: 'Import BuildingLevel1',
        fileName: 'buildingLevel1_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    buildingLevel2: {
      name: 'buildingLevel2',
      label: 'BuildingLevel2',
      menu: 'BuildingLevel2',
      exporterFileName: 'buildingLevel2_export',
      list: {
        menu: 'BuildingLevel2',
        title: 'BuildingLevel2',
      },
      create: {
        success: 'Building Level 2 saved successfully',
      },
      update: {
        success: 'Building Level 2 saved successfully',
      },
      destroy: {
        success: 'Building Level 2 deleted successfully',
      },
      destroyAll: {
        success: 'Building Level 2(s) deleted successfully',
      },
      edit: {
        title: 'Edit Building Level 2',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        customers: 'Customers',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Building Level 2',
      },
      view: {
        title: 'View Building Level 2',
      },
      importer: {
        title: 'Import BuildingLevel2',
        fileName: 'buildingLevel2_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    version: {
      name: 'version',
      label: 'Version',
      menu: 'Version',
      exporterFileName: 'Version_export',
      list: {
        menu: 'Versions',
        title: 'Versions',
      },
      create: {
        success: 'Version saved successfully',
      },
      update: {
        success: 'Version saved successfully',
      },
      destroy: {
        success: 'Version deleted successfully',
      },
      destroyAll: {
        success: 'Version(s) deleted successfully',
      },
      edit: {
        title: 'Edit Version',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        versionNumber: 'Version number',
        osApplication: 'Operating System',
        download: 'Download',
        identification: 'Identification',
        description: 'Description',
        customer: {
          phone: 'Customer - Phone',
          name: 'Customer - Name',
          email: 'Customer - Email'
        },
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        company: 'Company',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Version',
      },
      view: {
        title: 'View Version',
      },
      importer: {
        title: 'Import Versions',
        fileName: 'version_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    release: {
      name: 'release',
      label: 'release',
      menu: 'Release',
      exporterFileName: 'Release_export',
      list: {
        menu: 'Releases',
        title: 'Releases',
      },
      create: {
        success: 'Release saved successfully',
      },
      update: {
        success: 'Release saved successfully',
      },
      destroy: {
        success: 'Release deleted successfully',
      },
      destroyAll: {
        success: 'Release(s) deleted successfully',
      },
      edit: {
        title: 'Edit Release',
      },
      fields: {
        id: 'Id',
        ebox: 'Ebox',
        status: 'Status',
        scheduledAt: 'Update date',
        terminalUiVersion: 'Teminal version',
        identification: 'Identification',
        description: 'Description',
        customer: {
          phone: 'Customer - Phone',
          name: 'Customer - Name',
          email: 'Customer - Email'
        },
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        company: 'Company',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          SCHEDULED: 'Scheduled',
          CANCELED: 'Canceled',
        },
      },
      new: {
        title: 'New Release',
      },
      view: {
        title: 'View Release',
      },
      importer: {
        title: 'Import Releases',
        fileName: 'release_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount:
      'Already have an account? Sign in.',
    signinWithAnotherAccount:
      'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message:
        'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    customerEditor: {
      label: 'Customer Editor',
      description: 'Edit access to Customers',
    },
    customerViewer: {
      label: 'Customer Viewer',
      description: 'View access to Customers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderSmsResender: {
      label: 'Order Communication Resend',
      description: 'Resend SMS messages',
    },
    orderUpdateOverdue: {
      label: 'Update order overdue date',
      description: 'Allow user to update an order overdue',
    },
    orderUpdateStatus: {
      label: 'Update order status',
      description: 'Allow user to update an order status',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
    companyEditor: {
      label: 'Company Editor',
      description: 'Edit access to Companies',
    },
    companyViewer: {
      label: 'Company Viewer',
      description: 'View access to Companies',
    },
    eboxEditor: {
      label: 'E-BOX Editor',
      description: 'Edit access to E-BOX',
    },
    eboxViewer: {
      label: 'E-BOX Viewer',
      description: 'View access to E-BOX',
    },
    groupEditor: {
      label: 'Group Editor',
      description: 'Edit access to Groups',
    },
    groupViewer: {
      label: 'Group Viewer',
      description: 'View access to Groups',
    },
    packingEditor: {
      label: 'Packing Editor',
      description: 'Edit access to Packings',
    },
    packingViewer: {
      label: 'Packing Viewer',
      description: 'View access to Packings',
    },
    compartimentEditor: {
      label: 'Compartiment Editor',
      description: 'Edit access to Compartiments',
    },
    compartimentViewer: {
      label: 'Compartiment Viewer',
      description: 'View access to Compartiments',
    },
    webhookEditor: {
      label: 'Webhook Editor',
      description: 'Edit access to Webhooks',
    },
    webhookViewer: {
      label: 'Webhook Viewer',
      description: 'View access to Webhooks',
    },
    ccmEditor: {
      label: 'Ccm Editor',
      description: 'Edit access to CCM',
    },
    ccmViewer: {
      label: 'Ccm Viewer',
      description: 'View access to CCM',
    },
    qrcodeTemplateEditor: {
      label: 'QrcodeTemplate Editor',
      description: 'Edit access to QrcodeTemplate',
    },
    qrcodeTemplateViewer: {
      label: 'QrcodeTemplate Viewer',
      description: 'View access to QrcodeTemplate',
    },
    warehouseEditor: {
      label: 'Warehouse Editor',
      description: 'Edit access to Warehouses',
    },
    warehouseViewer: {
      label: 'Warehouse Viewer',
      description: 'View access to Warehouses',
    },
    buildingEditor: {
      label: 'Building Editor',
      description: 'Edit access to Buildings',
    },
    buildingViewer: {
      label: 'Building Viewer',
      description: 'View access to Buildings',
    },
    buildingLevel1Editor: {
      label: 'BuildingLevel1 Editor',
      description: 'Edit access to BuildingLevel1',
    },
    buildingLevel1Viewer: {
      label: 'BuildingLevel1 Viewer',
      description: 'View access to BuildingLevel1',
    },
    buildingLevel2Editor: {
      label: 'Building Level 2 Editor',
      description: 'Edit access to BuildingLevel2',
    },
    buildingLevel2Viewer: {
      label: 'Building Level 2 Viewer',
      description: 'View access to BuildingLevel2',
    },
    operator: {
      label: 'Operator',
      description: 'View access to operator',
    },
    operatorAdmin: {
      label: 'Admin Operator',
      desciption: 'Edit access to operator',
    }
  },

  permissions: {
    iamEdit: {
      label: 'iamEdit',
      description: ''
    },
    iamCreate: {
      label: 'iamCreate',
      description: ''
    },
    iamImport: {
      label: 'iamImport',
      description: ''
    },
    iamRead: {
      label: 'iamRead',
      description: ''
    },
    iamUserAutocomplete: {
      label: 'iamUserAutocomplete',
      description: ''
    },
    auditLogRead: {
      label: 'auditLogRead',
      description: ''
    },
    settingsEdit: {
      label: 'settingsEdit',
      description: ''
    },
    customerImport: {
      label: 'customerImport',
      description: ''
    },
    customerCreate: {
      label: 'customerCreate',
      description: ''
    },
    customerEdit: {
      label: 'customerEdit',
      description: ''
    },
    customerDestroy: {
      label: 'customerDestroy',
      description: ''
    },
    customerRead: {
      label: 'customerRead',
      description: ''
    },
    customerAutocomplete: {
      label: 'customerAutocomplete',
      description: ''
    },
    productImport: {
      label: 'productImport',
      description: ''
    },
    productCreate: {
      label: 'productCreate',
      description: ''
    },
    productEdit: {
      label: 'productEdit',
      description: ''
    },
    productDestroy: {
      label: 'productDestroy',
      description: ''
    },
    productRead: {
      label: 'productRead',
      description: ''
    },
    productAutocomplete: {
      label: 'productAutocomplete',
      description: ''
    },
    paymentCreate: {
      label: 'paymentCreate',
      description: ''
    },
    paymentEdit: {
      label: 'paymentEdit',
      description: ''
    },
    paymentDestroy: {
      label: 'paymentDestroy',
      description: ''
    },
    paymentRead: {
      label: 'paymentRead',
      description: ''
    },
    orderImport: {
      label: 'orderImport',
      description: ''
    },
    orderCreate: {
      label: 'orderCreate',
      description: ''
    },
    orderEdit: {
      label: 'orderEdit',
      description: ''
    },
    orderResendSMS: {
      label: 'orderResendSMS',
      description: ''
    },
    orderUpdateOverdue: {
      label: 'orderUpdateOverdue',
      description: ''
    },
    orderUpdateStatus: {
      label: 'orderUpdateStatus',
      description: ''
    },
    orderDestroy: {
      label: 'orderDestroy',
      description: ''
    },
    orderRead: {
      label: 'orderRead',
      description: ''
    },
    orderQrcode: {
      label: 'orderQrcode',
      description: ''
    },
    orderAutocomplete: {
      label: 'orderAutocomplete',
      description: ''
    },
    orderExport: {
      label: 'orderExport',
      description: ''
    },
    orderInbound: {
      label: 'orderInbound',
      description: ''
    },
    generateTag: {
      label: 'generateTag',
      description: ''
    },
    companyImport: {
      label: 'companyImport',
      description: ''
    },
    companyCreate: {
      label: 'companyCreate',
      description: ''
    },
    companyEdit: {
      label: 'companyEdit',
      description: ''
    },
    companyDestroy: {
      label: 'companyDestroy',
      description: ''
    },
    companyRead: {
      label: 'companyRead',
      description: ''
    },
    companyAutocomplete: {
      label: 'companyAutocomplete',
      description: ''
    },
    companyUserCreate: {
      label: 'companyUserCreate',
      description: ''
    },
    roleCreate: {
      label: 'roleCreate',
      description: ''
    },
    roleEdit: {
      label: 'roleEdit',
      description: ''
    },
    roleDestroy: {
      label: 'roleDestroy',
      description: ''
    },
    roleRead: {
      label: 'roleRead',
      description: ''
    },
    roleAutocomplete: {
      label: 'roleAutocomplete',
      description: ''
    },
    roleUserCreate: {
      label: 'roleUserCreate',
      description: ''
    },
    eboxImport: {
      label: 'eboxImport',
      description: ''
    },
    eboxCreate: {
      label: 'eboxCreate',
      description: ''
    },
    eboxEdit: {
      label: 'eboxEdit',
      description: ''
    },
    eboxDestroy: {
      label: 'eboxDestroy',
      description: ''
    },
    eboxRead: {
      label: 'eboxRead',
      description: ''
    },
    eboxAutocomplete: {
      label: 'eboxAutocomplete',
      description: ''
    },
    clusterImport: {
      label: 'clusterImport',
      description: ''
    },
    clusterCreate: {
      label: 'clusterCreate',
      description: ''
    },
    clusterEdit: {
      label: 'clusterEdit',
      description: ''
    },
    clusterDestroy: {
      label: 'clusterDestroy',
      description: ''
    },
    clusterRead: {
      label: 'clusterRead',
      description: ''
    },
    clusterAutocomplete: {
      label: 'clusterAutocomplete',
      description: ''
    },
    eboxModuleImport: {
      label: 'eboxModuleImport',
      description: ''
    },
    eboxModuleCreate: {
      label: 'eboxModuleCreate',
      description: ''
    },
    eboxModuleEdit: {
      label: 'eboxModuleEdit',
      description: ''
    },
    eboxModuleDestroy: {
      label: 'eboxModuleDestroy',
      description: ''
    },
    eboxModuleRead: {
      label: 'eboxModuleRead',
      description: ''
    },
    eboxModuleAutocomplete: {
      label: 'eboxModuleAutocomplete',
      description: ''
    },
    eboxReboot: {
      label: 'Reboot E-Box',
      description: ''
    },
    eboxRestartApp: {
      label: 'Restat App',
      description: ''
    },
    eboxSync: {
      label: 'Sync E-Box',
      description: ''
    },
    compartimentLayoutImport: {
      label: 'compartimentLayoutImport',
      description: ''
    },
    compartimentLayoutCreate: {
      label: 'compartimentLayoutCreate',
      description: ''
    },
    compartimentLayoutEdit: {
      label: 'compartimentLayoutEdit',
      description: ''
    },
    compartimentLayoutDestroy: {
      label: 'compartimentLayoutDestroy',
      description: ''
    },
    compartimentLayoutRead: {
      label: 'compartimentLayoutRead',
      description: ''
    },
    compartimentLayoutAutocomplete: {
      label: 'compartimentLayoutAutocomplete',
      description: ''
    },
    groupImport: {
      label: 'groupImport',
      description: ''
    },
    groupCreate: {
      label: 'groupCreate',
      description: ''
    },
    groupEdit: {
      label: 'groupEdit',
      description: ''
    },
    groupDestroy: {
      label: 'groupDestroy',
      description: ''
    },
    groupRead: {
      label: 'groupRead',
      description: ''
    },
    groupAutocomplete: {
      label: 'groupAutocomplete',
      description: ''
    },
    packingCreate: {
      label: 'packingCreate',
      description: ''
    },
    packingDestroy: {
      label: 'packingDestroy',
      description: ''
    },
    packingRead: {
      label: 'packingRead',
      description: ''
    },
    packingAutocomplete: {
      label: 'packingAutocomplete',
      description: ''
    },
    compartimentImport: {
      label: 'compartimentImport',
      description: ''
    },
    compartimentCreate: {
      label: 'compartimentCreate',
      description: ''
    },
    compartimentEdit: {
      label: 'compartimentEdit',
      description: ''
    },
    compartimentDestroy: {
      label: 'compartimentDestroy',
      description: ''
    },
    compartimentRead: {
      label: 'compartimentRead',
      description: ''
    },
    compartimentAutocomplete: {
      label: 'compartimentAutocomplete',
      description: ''
    },
    compartimentOpen: {
      label: 'Compartiment Open Port',
      description: ''
    },
    compartimentLock: {
      label: 'Compartiment Lock Port',
      description: ''
    },
    webhookImport: {
      label: 'webhookImport',
      description: ''
    },
    webhookCreate: {
      label: 'webhookCreate',
      description: ''
    },
    webhookEdit: {
      label: 'webhookEdit',
      description: ''
    },
    webhookDestroy: {
      label: 'webhookDestroy',
      description: ''
    },
    webhookRead: {
      label: 'webhookRead',
      description: ''
    },
    webhookAutocomplete: {
      label: 'webhookAutocomplete',
      description: ''
    },
    ccmImport: {
      label: 'ccmImport',
      description: ''
    },
    ccmCreate: {
      label: 'ccmCreate',
      description: ''
    },
    ccmEdit: {
      label: 'ccmEdit',
      description: ''
    },
    ccmDestroy: {
      label: 'ccmDestroy',
      description: ''
    },
    ccmRead: {
      label: 'ccmRead',
      description: ''
    },
    ccmAutocomplete: {
      label: 'ccmAutocomplete',
      description: ''
    },
    qrcodeTemplateImport: {
      label: 'qrcodeTemplateImport',
      description: ''
    },
    qrcodeTemplateCreate: {
      label: 'qrcodeTemplateCreate',
      description: ''
    },
    qrcodeTemplateEdit: {
      label: 'qrcodeTemplateEdit',
      description: ''
    },
    qrcodeTemplateDestroy: {
      label: 'qrcodeTemplateDestroy',
      description: ''
    },
    qrcodeTemplateRead: {
      label: 'qrcodeTemplateRead',
      description: ''
    },
    qrcodeTemplateAutocomplete: {
      label: 'qrcodeTemplateAutocomplete',
      description: ''
    },
    warehouseImport: {
      label: 'warehouseImport',
      description: ''
    },
    warehouseCreate: {
      label: 'warehouseCreate',
      description: ''
    },
    warehouseEdit: {
      label: 'warehouseEdit',
      description: ''
    },
    warehouseDestroy: {
      label: 'warehouseDestroy',
      description: ''
    },
    warehouseRead: {
      label: 'warehouseRead',
      description: ''
    },
    warehouseAutocomplete: {
      label: 'warehouseAutocomplete',
      description: ''
    },
    buildingImport: {
      label: 'buildingImport',
      description: ''
    },
    buildingCreate: {
      label: 'buildingCreate',
      description: ''
    },
    buildingEdit: {
      label: 'buildingEdit',
      description: ''
    },
    buildingDestroy: {
      label: 'buildingDestroy',
      description: ''
    },
    buildingRead: {
      label: 'buildingRead',
      description: ''
    },
    buildingAutocomplete: {
      label: 'buildingAutocomplete',
      description: ''
    },
    buildingLevel1Import: {
      label: 'buildingLevel1Import',
      description: ''
    },
    buildingLevel1Create: {
      label: 'buildingLevel1Create',
      description: ''
    },
    buildingLevel1Edit: {
      label: 'buildingLevel1Edit',
      description: ''
    },
    buildingLevel1Destroy: {
      label: 'buildingLevel1Destroy',
      description: ''
    },
    buildingLevel1Read: {
      label: 'buildingLevel1Read',
      description: ''
    },
    buildingLevel1Autocomplete: {
      label: 'buildingLevel1Autocomplete',
      description: ''
    },
    buildingLevel2Import: {
      label: 'buildingLevel2Import',
      description: ''
    },
    buildingLevel2Create: {
      label: 'buildingLevel2Create',
      description: ''
    },
    buildingLevel2Edit: {
      label: 'buildingLevel2Edit',
      description: ''
    },
    buildingLevel2Destroy: {
      label: 'buildingLevel2Destroy',
      description: ''
    },
    buildingLevel2Read: {
      label: 'buildingLevel2Read',
      description: ''
    },
    buildingLevel2Autocomplete: {
      label: 'buildingLevel2Autocomplete',
      description: ''
    },
    terminalOrderExpiredCollect: {
      label: 'terminalOrderExpiredCollect',
      description: ''
    },
    terminalOrderReturnedCollect: {
      label: 'terminalOrderReturnedCollect',
      description: ''
    },
    terminalOrderStore: {
      label: 'terminalOrderStore',
      description: ''
    },
    terminalCompartimentRead: {
      label: 'terminalCompartimentRead',
      description: ''
    },
    terminalCompartimentEdit: {
      label: 'terminalCompartimentEdit',
      description: ''
    },
    terminalUiReleaseCreate: {
      label: 'terminalUiReleaseCreate',
      description: ''
    },
    terminalUiReleaseEdit: {
      label: 'terminalUiReleaseEdit',
      description: ''
    },
    terminalUiReleaseDestroy: {
      label: 'terminalUiReleaseDestroy',
      description: ''
    },
    terminalUiReleaseRead: {
      label: 'terminalUiReleaseRead',
      description: ''
    },
    terminalUiReleaseAutocomplete: {
      label: 'terminalUiReleaseAutocomplete',
      description: ''
    },
    terminalUiVersionCreate: {
      label: 'terminalUiVersionCreate',
      description: ''
    },
    terminalUiVersionEdit: {
      label: 'terminalUiVersionEdit',
      description: ''
    },
    terminalUiVersionDestroy: {
      label: 'terminalUiVersionDestroy',
      description: ''
    },
    terminalUiVersionRead: {
      label: 'terminalUiVersionRead',
      description: ''
    },
    terminalUiVersionAutocomplete: {
      label: 'terminalUiVersionAutocomplete',
      description: ''
    },
    filterEbox: {
      label: 'filterEbox',
      description: ''
    },
    filterCluster: {
      label: 'filterCluster',
      description: ''
    },
    filterType: {
      label: 'filterType',
      description: ''
    },
    filterReferenceNumber: {
      label: 'filterReferenceNumber',
      description: ''
    },
    uploadFiles: {
      label: 'uploadFiles',
      description: ''
    },
    terminalCollectPending: {
      label: 'terminalCollectPending',
      description: ''
    },
    terminalShowEboxStatus: {
      label: 'terminalShowEboxStatus',
      description: ''
    },
    terminalOperatorCondominium: {
      label: 'terminalOperatorCondominium',
      description: ''
    },
    terminalShowPendingDepositOperator: {
      label: 'terminalShowPendingDepositOperator',
      description: ''
    },
    terminalMaintenance: {
      label: 'terminalMaintenance',
      description: ''
    },
    operationAutocomplete: {
      label: 'operationAutocomplete',
      description: ''
    },
    operationCreate: {
      label: 'operationCreate',
      description: ''
    },
    operationEdit: {
      label: 'operationEdit',
      description: ''
    },
    operationDestroy: {
      label: 'operationDestroy',
      description: ''
    },
    operationRead: {
      label: 'operationRead',
      description: ''
    },
    paymentGatewaysAutocomplete: {
      label: 'paymentGatewaysAutocomplete',
      description: ''
    },
    paymentGatewaysCreate: {
      label: 'paymentGatewaysCreate',
      description: ''
    },
    paymentGatewaysEdit: {
      label: 'paymentGatewaysEdit',
      description: ''
    },
    paymentGatewaysDestroy: {
      label: 'paymentGatewaysDestroy',
      description: ''
    },
    paymentGatewaysRead: {
      label: 'paymentGatewaysRead',
      description: ''
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists:
        'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      fieldResetPasword: 'User or Email',
      allowedCompanies: 'Allowed Companies',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      username: 'Username',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
      company: 'Company',
      useTemporaryToken: 'Use temporary token',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Dashboard',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/view/home/HomePage.js.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
    fields: {
      company: 'Company',
      data_relativa: 'Relative Date',
    },
    enumerators: {
      data_relativa: {
        today: 'Today',
        yesterday: 'Yesterday',
        past7days: 'Last 7 days',
        past30days: 'Last 30 days',
        lastweek: 'Last week',
        lastmonth: 'Last month',
        lastyear: 'Last year',
        thisweek: 'This week',
        thismonth: 'This month',
        thisyear: 'This year',
      },
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf:
        '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length:
        '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches:
        '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min:
        '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max:
        '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  notification: {
    send: 'Send Communication',
    resend: 'Resend Communication',
    success: 'Communication sent',
    error: 'Communication not sent'
  },

  openport: {
    success: 'Command has been sent ',
    error: 'Could not communicate with Locker.',
  },

  orderExport: {
    success: 'A link to download the data will be sent to your email as soon as the export process is done.',
    error: 'Cannot export orders.',
  },

  iamExport: {
    success: 'A link to download the data will be sent to your email as soon as the export process is done.',
    error: 'Cannot export data.',
  },

  subMenu: {
    locations: 'Locations',
    terminal: 'Terminal',
    system: 'System'
  }
};

export default en;
