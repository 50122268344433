const ptBR = {
  common: {
    clone: 'Clonar',
    or: 'ou',
    cancel: 'Cancelar',
    reset: 'Limpar',
    save: 'Salvar',
    search: 'Buscar',
    edit: 'Editar',
    remove: 'Remover',
    new: 'Novo',
    export: 'Exportar para Excel',
    noDataToExport: 'Não há dados para exportar',
    import: 'Importar',
    discard: 'Descartar',
    yes: 'Sim',
    no: 'Não',
    pause: 'Pausar',
    areYouSure: 'Tem certeza?',
    view: 'Visualizar',
    destroy: 'Deletar',
    generateTag: 'Gerar etiqueta',
    mustSelectARow: 'Selecione uma linha',
    back: 'Voltar',
    sendNotification: 'Enviar Notificação',
    openCompartiment: 'Abrir Compartimento',
    next: 'Próximo',
    previous: 'Anterior',
    print: 'Imprimir',
    finish: 'Finalizar',
  },

  app: {
    title: 'Clique Retire - IO',
  },

  entities: {
    customer: {
      name: 'Cliente',
      label: 'Clientes',
      menu: 'Clientes',
      exporterFileName: 'clientes_exportados',
      list: {
        menu: 'Clientes',
        title: 'Clientes',
      },
      create: {
        success: 'Cliente salvo com sucesso',
      },
      update: {
        success: 'Cliente salvo com sucesso',
      },
      destroy: {
        success: 'Cliente deletado com sucesso',
      },
      destroyAll: {
        success: 'Cliente(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Cliente',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        cellphone: 'Celular',
        email: 'E-mail',
        company: 'Empresa/Filial',
        buildingLevel2: 'Local',
        photo: 'Foto',
        pcd: 'Deficiente fisíco',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        documentNumber: 'Numero do Documento',
      },
      enumerators: {},
      new: {
        title: 'Novo Cliente',
      },
      view: {
        title: 'Visualizar Cliente',
      },
      importer: {
        title: 'Importar Cliente',
        fileName: 'cliente_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    product: {
      name: 'Produto',
      label: 'Produtos',
      menu: 'Produto',
      exporterFileName: 'produto_exportados',
      list: {
        menu: 'Produtos',
        title: 'Produtos',
      },
      create: {
        success: 'Produto salvo com sucesso',
      },
      update: {
        success: 'Produto salvo com sucesso',
      },
      destroy: {
        success: 'Produto deletado com sucesso',
      },
      destroyAll: {
        success: 'Produto(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Produto',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        description: 'Descrição',
        unitPriceRange: 'Preço Unitário',
        unitPrice: 'Preço Unitário',
        photos: 'Fotos',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Produto',
      },
      view: {
        title: 'Visualizar Produto',
      },
      importer: {
        title: 'Importar Produtos',
        fileName: 'produto_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    order: {
      name: 'Pedido',
      label: 'Pedidos',
      menu: 'Pedidos',
      exporterFileName: 'pedidos_exportados',
      list: {
        menu: 'Pedidos',
        title: 'Pedidos',
      },
      create: {
        success: 'Pedido salvo com sucesso',
      },
      update: {
        success: 'Pedido salvo com sucesso',
      },
      updateOverdue: {
        title: 'Alterar data expiração',
        success: 'Prazo de expiração atualizado com sucesso',
        error: 'Erro ao alterar o prazo de expiração',
      },
      destroy: {
        success: 'Pedido cancelado com sucesso',
      },
      destroyAll: {
        success: 'Pedido(s) cancelado(s) com sucesso',
      },
      generateTag: {
        success: 'Etiquetas geradas com sucesso',
      },
      edit: {
        title: 'Editar Pedido',
      },
      fields: {
        id: 'Id',
        type: 'Tipo',
        trackingCode: 'Número de rastreio/Pedido',
        referenceNumber: 'Número de Referência',
        shipper: 'Embarcador',
        company: 'Empresa/Filial',
        carrier: 'Transportadora',
        products: 'Produtos',
        ebox: 'Local',
        state: 'Estado (UF)',
        cluster: 'Cluster',
        notes: 'Observação',
        toCustomer: 'Destinatário/Cliente',
        accessCode: 'Código Acesso',
        attachments: 'Anexos',
        delivered: 'Entregue',
        fromCustomer: 'Remetente/Cliente',
        compartiment: 'Compartimento',
        compartimentNumber: 'Número do compartimento',
        inboundAtRange: 'Entrada no XD',
        inboundAt: 'Entrada no XD',
        inboundBy: 'Entrada no XD por',
        storedAtRange: 'Armazenado em',
        storedAt: 'Armazenado em',
        storedBy: 'Armazenado por',
        collectedAtRange: 'Coletado em',
        collectedAt: 'Coletado em',
        collectedBy: 'Coletado por',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        status: 'Status',
        overdueDate: 'Data de Expiração',
        compartimentSize: 'Tamanho do compartimento',
        customerName: 'Nome do Cliente',
        customerPhone: 'Telefone do Cliente',
        customerEmail: 'E-mail do Cliente',
        eboxCode: 'Código do Locker',
        notBeforeFirst: 'Não armazenar antes de',
        bookOrder: 'Reservar Compartimento',
        invoice: {
          series: 'Série da Nota Fiscal',
          number: 'Número da Nota Fiscal',
          key: 'Chave da Nota Fiscal',
          totalValue: 'Valor Total da Nota Fiscal',
          productsValue: 'Valor dos Produtos da Nota Fiscal',
          cfop: 'CFOP da Nota Fiscal',
          date: 'Data da Nota Fiscal',
          insured: 'Segurado'
        }
      },
      enumerators: {
        type: {
          DELIVERY: 'Entrega',
          RETURN: 'Retorno',
          STORAGE: 'Guarda-Volume',
          SEND: 'Home Delivery',
          SMARTDROP: 'SmartDrop'
        },
        status: {
          CREATED: 'Criado',
          INBOUND: 'Crossdocking',
          IN_TRANSIT_TO_STORE: 'Em trânsito para o locker',
          STORED: 'Armazenado',
          COURIER_STORED: 'Armazenado pelo Entregador',
          CUSTOMER_STORED: 'Armazenado pelo Cliente',
          CUSTOMER_TAKEN: 'Coletado pelo Usuário',
          CANCELED: 'Cancelado',
          EXPIRED: 'Expirado',
          EXPIRED_TAKEN: 'Expirados Coletados',
          EXPIRED_INBOUND: 'Expirados Recebidos no XD',
          EXPIRED_RETURNED: 'Expirados Devolvidos',
          COURIER_TAKEN: 'Coletado pelo operador',
          COURIER_INBOUND: 'Reversa Recebida no XD',
          COURIER_RETURNED: 'Reversa Devolvida',
          CUSTOMER_TAKEN_AND_WAITING_FOR_RELEASE: 'Coletado pelo Usuário e Aguardando Liberação',
          FAILED_DELIVERY: 'Falha na Entrega',
        },
        code: {
          EXPIRED: 'Expirado',
          MAINTENANCE: 'Manutenção',
          COURIER: 'Coleta pela Empresa',
          CUSTOMER_TAKEN: 'Coleta pelo Usuário',
          CUSTOMER_SEND: 'Armazenado pelo Cliente',
          CUSTOMER_STORAGE: 'Armazenado pelo Cliente',
          COURIER_TAKEN: 'Coleta pelo operador',
          CUSTOMER_RETURN: 'Retorno pelo Usuário'
        },
      },
      new: {
        title: 'Novo Pedido',
      },
      view: {
        title: 'Visualizar Pedido',
      },
      importer: {
        title: 'Importar Pedidos',
        fileName: 'pedido_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
      export: {
        exportMessageErroNotFilterData: 'Selecione um perído de data de criação antes de exportar',
      },
      filter: {
        dataPickerLimit: 'Selecione um período de no máximo 31 dias',
      },
      tracking: {
        buttonTracking: 'Ver tracking detalhado',
        titleTrackingModal: 'Tracking detalhado do pedido',
      }
    },

    payment: {
      name: 'Pagamentos',
      label: 'Pagamentos',
      menu: 'Pagamentos',
      exporterFileName: 'pagamentos_exportados',
      list: {
        menu: 'Pagamentos',
        title: 'Pagamentos',
      },
      create: {
        success: 'Pagamento salvo com sucesso',
      },
      update: {
        success: 'Pagamento salvo com sucesso',
      },
      destroy: {
        success: 'Pagamento cancelado com sucesso',
      },
      destroyAll: {
        success: 'Pagamento(s) cancelado(s) com sucesso',
      },
      edit: {
        title: 'Editar Pagamento',
      },
      fields: {
        id: 'Id',
        company: 'Empresa',
        ebox: 'Local',
        cluster: 'Cluster',
        compartiment: 'Compartimento',
        compartimentNumber: 'Número do compartimento',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        status: 'Status',
        overdueDate: 'Data de Expiração',
        value: "Valor",
        gateway: "Gateway",
        orderId: "Pedido",
        trackingCode: 'Número de rastreio/pedido',
        compartimentSize: 'Tamanho do compartimento',
      },
      enumerators: {
        status: {
          PENDING: 'Pendente',
          APPROVED: 'Aprovado',
          DECLINED: 'Recusado',
        },
      },
      new: {
        title: 'Novo Pagamento',
      },
      view: {
        title: 'Visualizar Pagamento',
      },
      importer: {
        title: 'Importar Pagamentos',
        fileName: 'pagamento_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    company: {
      name: 'Empresa',
      label: 'Empresas',
      menu: 'Empresas',
      exporterFileName: 'empresa_exportados',
      list: {
        menu: 'Empresas',
        title: 'Empresas',
      },
      create: {
        success: 'Empresa salva com sucesso',
      },
      update: {
        success: 'Empresa salva com sucesso',
      },
      destroy: {
        success: 'Empresa deletada com sucesso',
      },
      destroyAll: {
        success: 'Empresa(s) deletada com sucesso',
      },
      edit: {
        title: 'Editar Empresa',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        logo: 'Logo',
        type: 'Tipo',
        externalId: 'ID Externo',
        allowCollectExpiredOrder: 'Permitir coleta expirada',
        group: 'Limitar acesso a um grupo de lockers',
        distributionLogistic: 'Operar empresas',
        attachments: 'Anexos',
        parent: 'Parent',
        building: 'Construção',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        overdueData: 'Dias Vencimento Entrega',
        orderTypes: 'Operações habilitadas',
        smsGateway: 'Provedor SMS'
      },
      enumerators: {
        type: {
          shipper: 'Embarcador',
          carrier: 'Transportadora',
          building: 'Construção',
        },
      },
      new: {
        title: 'Nova Empresa',
      },
      view: {
        title: 'Visualizar Empresa',
      },
      importer: {
        title: 'Importar Empresas',
        fileName: 'company_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    role: {
      name: 'Perfil',
      label: 'Perfis',
      menu: 'Perfis',
      exporterFileName: 'perfil_exportados',
      list: {
        menu: 'Perfis',
        title: 'Perfis',
      },
      create: {
        success: 'Perfil salva com sucesso',
      },
      update: {
        success: 'Perfil salva com sucesso',
      },
      destroy: {
        success: 'Perfil deletada com sucesso',
      },
      destroyAll: {
        success: 'Perfil(s) deletada com sucesso',
      },
      edit: {
        title: 'Editar Perfil',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        description: 'Descrição',
        company: 'Empresa',
        permissions: 'Permissões',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em'
      },
      new: {
        title: 'Nova Perfil',
      },
      view: {
        title: 'Visualizar Perfil',
      },
      importer: {
        title: 'Importar Perfis',
        fileName: 'role_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    ebox: {
      name: 'E-BOX',
      label: 'E-BOX',
      menu: 'E-BOX',
      exporterFileName: 'E-BOX_exportados',
      list: {
        menu: 'E-BOX',
        title: 'E-BOX',
      },
      create: {
        success: 'E-BOX salvo com sucesso',
      },
      update: {
        success: 'E-BOX salvo com sucesso',
      },
      destroy: {
        success: 'E-BOX deletado com sucesso',
      },
      destroyAll: {
        success: 'E-BOX(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar E-BOX',
      },
      clone: {
        title: 'Clonar E-BOX'
      },
      generateConfig: {
        title: 'Gerar Arquivo de Configuração',
        success: 'Arquivo de configuração gerado com sucesso',
        error: 'Erro ao gerar arquivo de configuração'
      },
      generateCompartiments: {
        title: 'Gerar Compartimentos',
        submit: 'Gerar',
        hint: 'Essa ação irá excluir todos os compartimentos e gerar novos',
      },
      settings: {
        title: 'Configurações',
        reboot: {
          title: 'Reiniciar E-BOX',
          success: 'Solicitação de reiniciar E-BOX enviada com sucesso',
          error: 'Erro ao solicitar reiniciar E-BOX'
        },
        close_app: {
          title: 'Reiniciar Aplicativo',
          success: 'Solicitação de reiniciar aplicativo enviada com sucesso',
          error: 'Erro ao solicitar reiniciar aplicativo'
        },
        force_sync: {
          title: 'Sincronizar E-BOX',
          success: 'Solicitação de sincronizar E-BOX enviada com sucesso',
          error: 'Erro ao solicitar sincronizar E-BOX'
        },
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        code: 'Code',
        images: 'Imagens',
        enable: 'Habilitado',
        status: 'Status',
        street: 'Endereço',
        number: 'Número',
        complement: 'Complemento',
        neighborhood: 'Bairro',
        city: 'Cidade',
        terminalId: 'Device Id',
        state: 'Estado',
        postalCode: 'CEP',
        coordinates: 'Coordenadas (Latitude, Longitude)',
        warehouse: 'Warehouse',
        company: 'Empresa',
        eboxModule: 'Módulos',
        startNumber: 'Numeração Inicial',
        boardNumber: 'Número da placa',
        building: 'Construção',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        lastHeartBeat: 'Última atividade',
        osVersion: 'Versão OS',
        shared: 'Compartilhado',
        workingHours: 'Horários de Funcionamento',
        application: 'App',
        applicationVersion: 'Versão App',
      },
      enumerators: {
        status: {
          online: 'Online',
          offline: 'Offline',
        },
        osApplication: {
          windows: 'Windows',
          linux: 'Linux',
          mac: 'Mac',
        },
      },
      new: {
        title: 'Novo E-BOX',
      },
      view: {
        title: 'Visualizar E-BOX',
      },
      importer: {
        title: 'Importar E-BOX',
        fileName: 'ebox_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    address: {
      name: 'Endereço Entrega',
      label: 'Endereço',
      menu: 'Endereço',
      exporterFileName: 'enderecos_exportados',
      list: {
        menu: 'Endereço',
        title: 'Endereço',
      },
      create: {
        success: 'Endereço salvo com sucesso',
      },
      update: {
        success: 'Endereço salvo com sucesso',
      },
      destroy: {
        success: 'Endereço deletado com sucesso',
      },
      destroyAll: {
        success: 'Endereço deletado com sucesso',
      },
      edit: {
        title: 'Editar Endereço',
      },
      fields: {
        id: 'Id',
        street: 'Endereço',
        number: 'Número',
        additional: 'Complemento',
        reference: 'Referência',
        neighborhood: 'Bairro',
        city: 'Cidade',
        state: 'Estado',
        postalCode: 'CEP',
        country: 'País',
        company: 'Empresa',
        coordinates: 'Coordenadas (Latitude, Longitude)',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em'
      },
      new: {
        title: 'Novo Endereço',
      },
      view: {
        title: 'Visualizar Endereço',
      },
      importer: {
        title: 'Importar Endereço',
        fileName: 'endereco_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    cluster: {
      name: 'Cluster',
      label: 'Cluster',
      menu: 'Cluster',
      exporterFileName: 'Cluster_exportados',
      list: {
        menu: 'Cluster',
        title: 'Cluster',
      },
      create: {
        success: 'Cluster salvo com sucesso',
      },
      update: {
        success: 'Cluster salvo com sucesso',
      },
      destroy: {
        success: 'Cluster deletado com sucesso',
      },
      destroyAll: {
        success: 'Cluster(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Cluster',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        sequence: 'Sequencial para Ordenação de Compartimentos',
        enable: 'Habilitado',
        clusterEbox: 'Cluster eBoxes',
        company: 'Empresa',
        operationType: 'Tipo de Operação',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        sequence: {
          'A-Z': 'A-Z',
          '1-9999': '1-9999',
        },
        operationType: {
          RANGE: 'PERIODO',
          HOUR: 'HORA',
        },
      },
      new: {
        title: 'Novo Cluster',
      },
      view: {
        title: 'Visualizar Cluster',
      },
      importer: {
        title: 'Importar Cluster',
        fileName: 'cluster_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
      compartiment: {
        title: 'Compartimentos',
        size: 'Tamanho',
        available: 'Disponíveis',
        damaged: 'Danificados',
        occupied: 'Ocupados',
        booked: 'Reservados',
        total: 'Total'
      }
    },

    clusterEbox: {
      name: 'ClusterEbox',
      label: 'Cluster eBox',
      menu: 'Cluster - e-Box',
      exporterFileName: 'ClusterEbox_exportados',
      list: {
        menu: 'ClusterEbox',
        title: 'ClusterEbox',
      },
      create: {
        success: 'Cluster Ebox salvo com sucesso',
      },
      update: {
        success: 'Cluster Ebox salvo com sucesso',
      },
      destroy: {
        success: 'Cluster Ebox deletado com sucesso',
      },
      destroyAll: {
        success: 'Cluster Ebox(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Cluster Ebox',
      },
      fields: {
        id: 'Id',
        cluster: 'Cluster',
        clusterName: 'Nome do Cluster',
        ebox: 'e-Box',
        eboxCode: 'Código do e-Box',
        sequenceCompartiments: 'Ordenação para sequência de compartimentos',
        clusterPosition: 'Posição no cluster',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        sequenceCompartiments: {
          'RL-TB': 'RL-TB',
          'RL-BT': 'RL-BT',
          'LR-TB': 'LR-TB',
          'LR-BT': 'LR-BT',
        },
      },
      new: {
        title: 'Novo Cluster Ebox',
      },
      view: {
        title: 'Visualizar Cluster Ebox',
      },
      importer: {
        title: 'Importar Cluster Ebox',
        fileName: 'clusterEbox_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    eboxModule: {
      name: 'Módulos e-Box',
      label: 'Módulos e-Box',
      menu: 'Módulos e-Box',
      exporterFileName: 'EboxModule_exportados',
      list: {
        menu: 'Módulos e-Box',
        title: 'Módulos e-Box',
      },
      create: {
        success: 'Módulo salvo com sucesso',
      },
      update: {
        success: 'Módulo salvo com sucesso',
      },
      destroy: {
        success: 'Módulo deletado com sucesso',
      },
      destroyAll: {
        success: 'Módulo(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Módulo',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        code: 'Código',
        enable: 'Habilitado',
        eboxModuleCompartiment: 'Compartimentos',
        images: 'Imagens',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        defaultBoardNumber: 'Número padrão da placa'
      },
      new: {
        title: 'Novo Módulo',
      },
      view: {
        title: 'Visualizar Módulo',
      },
      importer: {
        title: 'Importar Módulo',
        fileName: 'eboxModule_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    eboxModuleCompartiment: {
      name: 'Compartimentos Módulo',
      label: 'Compartimentos Módulo',
      menu: 'Compartimentos Módulo',
      exporterFileName: 'EboxModuleCompartiment_exportados',
      list: {
        menu: 'Compartimentos Módulo',
        title: 'Compartimentos Módulo',
      },
      create: {
        success: 'Compartimento salvo com sucesso',
      },
      update: {
        success: 'Compartimento salvo com sucesso',
      },
      destroy: {
        success: 'Compartimento deletado com sucesso',
      },
      destroyAll: {
        success: 'Compartimento(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Compartimento',
      },
      fields: {
        id: 'Id',
        staticNumber: 'Número físico',
        eboxModule: 'Módulo e-Box',
        compartimentLayout: 'Layout',
        pcd: 'PCD',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        priority: 'Prioridade'
      },
      new: {
        title: 'Novo Compartimento',
      },
      view: {
        title: 'Visualizar Compartimento',
      },
      importer: {
        title: 'Importar Compartimento',
        fileName: 'eboxModuleCompartiment_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    compartimentLayout: {
      name: 'Compartimento Layout',
      label: 'Compartimento Layout',
      menu: 'Compartimento Layout',
      exporterFileName: 'CompartimentLayout_exportados',
      list: {
        menu: 'Compartimento Layout',
        title: 'Compartimento Layout',
      },
      create: {
        success: 'Layout salvo com sucesso',
      },
      update: {
        success: 'Layout salvo com sucesso',
      },
      destroy: {
        success: 'Layout deletado com sucesso',
      },
      destroyAll: {
        success: 'Layout(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Layout',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        code: 'Código',
        width: 'Comprimento',
        height: 'Altura',
        depth: 'Largura',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      new: {
        title: 'Novo Layout',
      },
      view: {
        title: 'Visualizar Layout',
      },
      importer: {
        title: 'Importar Layout',
        fileName: 'compartimentLayout_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    group: {
      name: 'Grupo',
      label: 'Grupos',
      menu: 'Grupos',
      exporterFileName: 'Grupos_exportados',
      list: {
        menu: 'Grupos',
        title: 'Grupos',
      },
      create: {
        success: 'Grupo salvo com sucesso',
      },
      update: {
        success: 'Grupo salvo com sucesso',
      },
      destroy: {
        success: 'Grupo deletado com sucesso',
      },
      destroyAll: {
        success: 'Grupo(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Grupo',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        ebox: 'E-BOX',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Grupo',
      },
      view: {
        title: 'Visualizar Grupo',
      },
      importer: {
        title: 'Importar Grupos',
        fileName: 'group_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    packing: {
      name: 'Romaneio',
      label: 'Romaneios',
      menu: 'Romaneio',
      exporterFileName: 'romaneio_exportados',
      list: {
        menu: 'Romaneios',
        title: 'Romaneios',
      },
      create: {
        success: 'Romaneio salvo com sucesso',
      },
      update: {
        success: 'Romaneio salvo com sucesso',
      },
      destroy: {
        success: 'Romaneio deletado com sucesso',
      },
      destroyAll: {
        success: 'Romaneio(s) deletado com sucesso',
      },
      finish: {
        success: 'Romaneio finalizado com sucesso',
      },
      edit: {
        title: 'Editar Romaneio',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        orders: 'Pedidos',
        eboxes: 'Locais',
        operator: 'Operador Responsável',
        picking: 'Modalidade',
        warehouse: 'Warehouse',
        trackingCode: 'Tracking Code Pedido',
        ebox: 'Locker',
        finishedAt: 'Finalizado',
        finishedBy: 'Finalizado por',
        notes: 'Observações',
        company: 'Company',
        totalOrders: 'Total de Pedidos',
        packingOrders: 'Pedidos no Romaneio',
        createdAt: 'Criado em',
        createdBy: 'Criado por',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        picking: {
          return: 'Retorno',
          'collect/delivery': 'Coleta e Entrega',
        }
      },
      new: {
        title: 'Novo Romaneio',
      },
      view: {
        title: 'Visualizar Romaneio',
      },
      importer: {
        title: 'Importar Romaneios',
        fileName: 'packing_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    compartiment: {
      name: 'Compartimento',
      label: 'Compartimentos',
      menu: 'Compartimentos',
      exporterFileName: 'Compartimento_exportados',
      list: {
        menu: 'Compartimentos',
        title: 'Compartimentos',
      },
      create: {
        success: 'Compartimento salvo com sucesso',
      },
      update: {
        success: 'Compartimento salvo com sucesso',
      },
      destroy: {
        success: 'Compartimento deletado com sucesso',
      },
      destroyAll: {
        success: 'Compartimento(s) deletado com sucesso',
      },
      lock: {
        damage: {
          title: 'Trancar',
          success: 'Compartimento trancado com sucesso'
        },
        available: {
          title: 'Liberar',
          success: 'Compartimento liberado com sucesso'
        }
      },
      edit: {
        title: 'Editar Compartimento',
      },
      fields: {
        id: 'Id',
        numberRange: 'Número',
        number: 'Número',
        boardNumberRange: 'Número da Placa',
        numberInColumn: 'Número - Coluna',
        boardNumber: 'Número - Placa',
        comNumber: 'Placa COM',
        usageType: 'Tipo de Uso',
        size: 'Tamanho',
        ebox: 'E-BOX',
        status: 'Status',
        pcd: 'Deficiente físico',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
        width: 'Largura',
        height: 'Altura',
        depth: 'Profundidade',
      },
      enumerators: {
        status: {
          AVAILABLE: 'DISPONÍVEL',
          OCCUPIED: 'OCUPADO',
          DAMAGE: 'AVARIA',
          DIRTY: 'SUJO',
          BOOKED: 'RESERVADO',
        },
        usageType: {
          DELIVERY: 'Pacote',
          LAUNDRY: 'Lavanderia',
          FOOD: 'Comida',
          DROP: 'Drop',
        },
      },
      new: {
        title: 'Novo Compartimento',
      },
      view: {
        title: 'Visualizar Compartimento',
      },
      importer: {
        title: 'Importar Compartimentos',
        fileName: 'compartimento_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    webhook: {
      name: 'Webhook',
      label: 'Webhooks',
      menu: 'Webhooks',
      exporterFileName: 'Webhook_exportados',
      list: {
        menu: 'Webhooks',
        title: 'Webhooks',
      },
      create: {
        success: 'Webhook salvo com sucesso',
      },
      update: {
        success: 'Webhook salvo com sucesso',
      },
      destroy: {
        success: 'Webhook deletado com sucesso',
      },
      destroyAll: {
        success: 'Webhook(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Webhook',
      },
      fields: {
        id: 'Id',
        url: 'Url',
        company: 'Empresa',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Webhook',
      },
      view: {
        title: 'Visualizar Webhook',
      },
      importer: {
        title: 'Importar Webhooks',
        fileName: 'webhook_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    ccm: {
      name: 'Ccm',
      label: 'CCM',
      menu: 'CCM',
      exporterFileName: 'Ccm_exportados',
      list: {
        menu: 'CCM',
        title: 'CCM',
      },
      create: {
        success: 'Ccm salvo com sucesso',
      },
      update: {
        success: 'Ccm salvo com sucesso',
      },
      destroy: {
        success: 'Ccm deletado com sucesso',
      },
      destroyAll: {
        success: 'Ccm(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Ccm',
      },
      fields: {
        id: 'Id',
        type: 'Tipo',
        subType: 'Tipo do Pedido',
        cluster: 'Cluster',
        content: 'Conteúdo',
        subject: 'Assunto',
        trigger: 'Gatilho',
        company: 'Empresa',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        type: {
          sms: 'Sms',
          email: 'Email',
          push: 'Push',
        },
        subType: {
          delivery: 'Retirada',
          return: 'Retorno',
          storage: 'Guarda-Volume',
          send: 'Envio'
        },
        trigger: {
          CREATED: 'Pedido Criado',
          STORED: 'Armazenado no e-Box',
          EXPIRED: 'Expirado',
          INBOUND: 'Recebido no XD',
          IN_TRANSIT_TO_STORE: 'Em trânsito para o locker',
          EXPIRED_TAKEN: 'Expirado Coletado',
          EXPIRED_INBOUND: 'Expirado Recebido no XD',
          EXPIRED_RETURNED: 'Expirado Devolvido',
          COURIER_TAKEN: 'Coletado pelo operador',
          COURIER_INBOUND: 'Reversa Recebida no XD',
          COURIER_RETURNED: 'Reversa Devolvida',
          CUSTOMER_TAKEN: 'Retirado pelo usuário',
          PRE_EXPIRED: 'Pré Expirado',
          CANCELED: 'Cancelado',
        },
      },
      new: {
        title: 'Novo Ccm',
      },
      view: {
        title: 'Visualizar Ccm',
      },
      importer: {
        title: 'Importar CCM',
        fileName: 'ccm_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },
    qrcodeTemplate: {
      name: 'QrcodeTemplate',
      label: 'Qrcode Template',
      menu: 'Qrcode Template',
      exporterFileName: 'QrcodeTemplate_exportados',
      list: {
        menu: 'Qrcode Template',
        title: 'Qrcode Template',
      },
      create: {
        success: 'QrcodeTemplate salvo com sucesso',
      },
      update: {
        success: 'QrcodeTemplate salvo com sucesso',
      },
      destroy: {
        success: 'QrcodeTemplate deletado com sucesso',
      },
      destroyAll: {
        success: 'QrcodeTemplate(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar QrcodeTemplate',
      },
      fields: {
        id: 'Id',
        type: 'Tipo',
        hbsContent: 'Handlebar Template',
        company: 'Empresa',
        enable: 'Habilitado',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        type: {
          STORED: 'Disponível para retirada',
          CUSTOMER_TAKEN: 'Já retirado pelo usuario',
          CUSTOMER_SEND: 'Disponível para o usuário armazenar',
          EXPIRED: 'Expirado',
        }
      },
      new: {
        title: 'Novo QrcodeTemplate',
      },
      view: {
        title: 'Visualizar QrcodeTemplate',
      },
      importer: {
        title: 'Importar Qrcode Template',
        fileName: 'qrcodeTemplate_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },
    warehouse: {
      name: 'Warehouse',
      label: 'Warehouses',
      menu: 'Warehouses',
      exporterFileName: 'Warehouse_exportados',
      list: {
        menu: 'Warehouses',
        title: 'Warehouses',
      },
      create: {
        success: 'Warehouse salvo com sucesso',
      },
      update: {
        success: 'Warehouse salvo com sucesso',
      },
      destroy: {
        success: 'Warehouse deletado com sucesso',
      },
      destroyAll: {
        success: 'Warehouse(s) deletado com sucesso',
      },
      edit: {
        title: 'Editar Warehouse',
      },
      fields: {
        id: 'Id',
        company: 'Company',
        street: 'Street',
        name: 'Nome',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Warehouse',
      },
      view: {
        title: 'Visualizar Warehouse',
      },
      importer: {
        title: 'Importar Warehouses',
        fileName: 'warehouse_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    building: {
      name: 'Construção',
      label: 'Construções',
      menu: 'Construções',
      exporterFileName: 'Construcoes_exportados',
      list: {
        menu: 'Construções',
        title: 'Construções',
      },
      create: {
        success: 'Construçõe salva com sucesso',
      },
      update: {
        success: 'Construção salva com sucesso',
      },
      destroy: {
        success: 'Construção deletada com sucesso',
      },
      destroyAll: {
        success: 'Construção deletada com sucesso',
      },
      edit: {
        title: 'Editar Construção',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        company: 'Empresa',
        level1: 'Nivel 1',
        level2: 'Nível 2',
        externalId: 'ID Externo',
        buildingId: 'ID Construção',
        customer: {
          phone: 'Cliente - Celular',
          name: 'Cliente - Nome',
          email: 'Cliente - Email',
        },
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Nova Construção',
      },
      view: {
        title: 'Visualizar Construção',
      },
      importer: {
        title: 'Importar Construções',
        fileName: 'building_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    buildingLevel1: {
      name: 'Nível 1',
      label: 'Nível 1',
      menu: 'Nível 1',
      exporterFileName: 'nivel1_exportados',
      list: {
        menu: 'Nível 1',
        title: 'Nível 1',
      },
      create: {
        success: 'Nível 1 salvo com sucesso',
      },
      update: {
        success: 'Nível 1 salvo com sucesso',
      },
      destroy: {
        success: 'Nível 1 deletado com sucesso',
      },
      destroyAll: {
        success: 'Nível 1 deletado com sucesso',
      },
      edit: {
        title: 'Editar Nível 1',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        level2: 'Nível 2',
        company: 'Empresa',
        building: 'Construção',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Nível 1',
      },
      view: {
        title: 'Visualizar Nível 1',
      },
      importer: {
        title: 'Importar Nível 1',
        fileName: 'nivel1_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    buildingLevel2: {
      name: 'Nível 2',
      label: 'Nível 2',
      menu: 'Nível 2',
      exporterFileName: 'nivel2_exportados',
      list: {
        menu: 'Nível 2',
        title: 'Nível 2',
      },
      create: {
        success: 'Nível 2 salvo com sucesso',
      },
      update: {
        success: 'Nível 2 salvo com sucesso',
      },
      destroy: {
        success: 'Nível 2 deletado com sucesso',
      },
      destroyAll: {
        success: 'Nível 2 deletado com sucesso',
      },
      edit: {
        title: 'Editar Nível 2',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        customers: 'Usuários',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Novo Nível 2',
      },
      view: {
        title: 'Visualizar Nível 2',
      },
      importer: {
        title: 'Importar Nível 2',
        fileName: 'nivel2_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },

    version: {
      name: 'Versões',
      label: 'Versões',
      menu: 'Versões',
      exporterFileName: 'Versões_exportados',
      list: {
        menu: 'Versões',
        title: 'Versões',
      },
      create: {
        success: 'Versão salva com sucesso',
      },
      update: {
        success: 'Versão salva com sucesso',
      },
      destroy: {
        success: 'Versão deletada com sucesso',
      },
      destroyAll: {
        success: 'Versão deletada com sucesso',
      },
      edit: {
        title: 'Editar Versão',
      },
      fields: {
        id: 'Id',
        name: 'Nome',
        versionNumber: 'Número da Versão',
        osApplication: 'Sistema Operacional',
        company: 'Empresa',
        download: 'Download',
        identification: 'Identificação',
        description: 'Descrição',
        customer: {
          phone: 'Cliente - Celular',
          name: 'Cliente - Nome',
          email: 'Cliente - Email',
        },
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {},
      new: {
        title: 'Nova Versão',
      },
      view: {
        title: 'Visualizar Versão',
      },
      importer: {
        title: 'Importar Versão',
        fileName: 'version_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },


    release: {
      name: 'Lançamentos',
      label: 'Lançamentos',
      menu: 'Lançamentos',
      exporterFileName: 'Lançamentos_exportados',
      list: {
        menu: 'Lançamentos',
        title: 'Lançamentos',
      },
      create: {
        success: 'Lançamento salvo com sucesso',
      },
      update: {
        success: 'Lançamento salvo com sucesso',
      },
      destroy: {
        success: 'Lançamento deletada com sucesso',
      },
      destroyAll: {
        success: 'Lançamento deletado com sucesso',
      },
      edit: {
        title: 'Editar Lançamento',
      },
      fields: {
        id: 'Id',
        company: 'Empresa',
        ebox: 'Ebox',
        status: 'Status',
        identification: 'Identificação',
        terminalUiVersion: 'Versão do terminal',
        scheduledAt: 'Data para atualização',
        description: 'Descrição',
        customer: {
          phone: 'Cliente - Celular',
          name: 'Cliente - Nome',
          email: 'Cliente - Email',
        },
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        createdAtRange: 'Criado em',
      },
      enumerators: {
        status: {
          SCHEDULED: 'Agendado',
          CANCELED: 'Cancelado',
        },
      },
      new: {
        title: 'Novo Lançamento',
      },
      view: {
        title: 'Visualizar Lançamentos',
      },
      importer: {
        title: 'Importar Lançamento',
        fileName: 'lançamento_template_importacao',
        hint:
          'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Editar Perfil',
      success: 'Perfil atualizado com sucesso',
    },
    createAnAccount: 'Criar uma conta',
    rememberMe: 'Lembrar-me',
    forgotPassword: 'Esqueci minha senha',
    signin: 'Entrar',
    signup: 'Registrar',
    signout: 'Sair',
    alreadyHaveAnAccount: 'Já possui uma conta? Entre.',
    signinWithAnotherAccount: 'Entrar com outra conta',
    emailUnverified: {
      message: `Por favor, confirme seu email em <strong>{0}</strong> para continuar.`,
      submit: `Reenviar confirmação por email`,
    },
    emptyPermissions: {
      message: `Você ainda não possui permissões. Aguarde o administrador conceder seus privilégios.`,
    },
    passwordResetEmail: {
      message: 'Enviar email de redefinição de senha',
      error: `Email não encontrado`,
    },
    passwordReset: {
      message: 'Alterar senha',
    },
    emailAddressVerificationEmail: {
      error: `Email não encontrado`,
    },
    verificationEmailSuccess: `Verificação de email enviada com sucesso`,
    passwordResetEmailSuccess: `Email de redefinição de senha enviado com sucesso`,
    passwordResetSuccess: `Senha alterada com sucesso`,
    verifyEmail: {
      success: 'Email verificado com sucesso',
      message:
        'Aguarde um momento, seu email está sendo verificado...',
    },
  },

  roles: {
    owner: {
      label: 'Proprietário',
      description: 'Acesso completo a todos os recursos',
    },
    editor: {
      label: 'Editor',
      description: 'Acesso para edição a todos os recursos',
    },
    companyUserCreator: {
      label: 'Criador de Usuário',
      description: 'Acesso para criação de usuários',
    },
    viewer: {
      label: 'Visualizador',
      description:
        'Acesso de visualização a todos os recursos',
    },
    auditLogViewer: {
      label: 'Visualizador de Registros de Autoria',
      description:
        'Acesso de visualização dos registros de autoria',
    },
    iamSecurityReviewer: {
      label: 'Revisor de segurança',
      description: `Acesso total para gerenciar as funções do usuário`,
    },
    entityEditor: {
      label: 'Editor de Entidades',
      description: 'Acesso de edição a todas as entidades',
    },
    entityViewer: {
      label: 'Visualizador de Entidades',
      description:
        'Acesso de visualização a todas as entidades',
    },
    customerEditor: {
      label: 'Editor de Clientes',
      description: 'Acesso de edição aos Clientes',
    },
    customerViewer: {
      label: 'Visualizador de Clientes',
      description: 'Acesso de visualização aos Clientes',
    },
    productEditor: {
      label: 'Editor de Produtos',
      description: 'Acesso de edição aos Produtos',
    },
    productViewer: {
      label: 'Visualizador de Produtos',
      description: 'Acesso de visualização aos Produtos',
    },
    orderEditor: {
      label: 'Editor de Pedidos',
      description: 'Acesso de edição aos Pedidos',
    },
    orderSmsResender: {
      label: 'Reenviar Comunicações de Pedidos',
      description: 'Autoriza o reenvio das comunicações por SMS',
    },
    orderUpdateOverdue: {
      label: 'Alterar prazo de expiração de Pedidos',
      description: 'Autoriza a edição do prazo de expiração do pedido',
    },
    orderUpdateStatus: {
      label: 'Alterar status de Pedidos',
      description: 'Autoriza a edição do status do pedido',
    },
    orderViewer: {
      label: 'Visualizador de Pedidos',
      description: 'Acesso de visualização aos Pedidos',
    },
    companyEditor: {
      label: 'Editor de Empresas',
      description: 'Acesso de edição aos Empresas',
    },
    companyViewer: {
      label: 'Visualizador de Empresas',
      description: 'Acesso de visualização aos Empresas',
    },
    eboxEditor: {
      label: 'Editor de E-BOX',
      description: 'Acesso de edição aos E-BOX',
    },
    eboxViewer: {
      label: 'Visualizador de E-BOX',
      description: 'Acesso de visualização aos E-BOX',
    },
    clusterEditor: {
      label: 'Editor de Cluster',
      description: 'Acesso de edição aos Clusters',
    },
    clusterViewer: {
      label: 'Visualizador de Cluster',
      description: 'Acesso de visualização aos Clusters',
    },
    eboxModuleEditor: {
      label: 'Editor de Módulos de e-Box',
      description: 'Acesso de edição aos Módulos',
    },
    eboxModuleViewer: {
      label: 'Visualizador de Módulos de e-Box',
      description: 'Acesso de visualização aos Módulos',
    },
    compartimentLayoutEditor: {
      label: 'Editor de Layout de Compartimento',
      description: 'Acesso de edição aos de Layouts de Compartimentos',
    },
    compartimentLayoutViewer: {
      label: 'Visualizador de Layout de Compartimentos',
      description: 'Acesso de visualização dos Layouts',
    },
    groupEditor: {
      label: 'Editor de Grupos',
      description: 'Acesso de edição aos Grupos',
    },
    groupViewer: {
      label: 'Visualizador de Grupos',
      description: 'Acesso de visualização aos Grupos',
    },
    packingEditor: {
      label: 'Editor de Romaneios',
      description: 'Acesso de edição aos Romaneios',
    },
    packingViewer: {
      label: 'Visualizador de Romaneios',
      description: 'Acesso de visualização aos Romaneios',
    },
    compartimentEditor: {
      label: 'Editor de Compartimentos',
      description: 'Acesso de edição aos Compartimentos',
    },
    compartimentViewer: {
      label: 'Visualizador de Compartimentos',
      description:
        'Acesso de visualização aos Compartimentos',
    },
    webhookEditor: {
      label: 'Editor de Webhooks',
      description: 'Acesso de edição aos Webhooks',
    },
    webhookViewer: {
      label: 'Visualizador de Webhooks',
      description: 'Acesso de visualização aos Webhooks',
    },
    ccmEditor: {
      label: 'Editor de CCM',
      description: 'Acesso de edição aos CCM',
    },
    ccmViewer: {
      label: 'Visualizador de CCM',
      description: 'Acesso de visualização aos CCM',
    },
    qrcodeTemplateEditor: {
      label: 'Editor de Qrcode Template',
      description: 'Acesso de edição aos Qrcode Template',
    },
    qrcodeTemplateViewer: {
      label: 'Visualizador de Qrcode Template',
      description: 'Acesso de visualização aos Qrcode Template',
    },
    warehouseEditor: {
      label: 'Editor de Warehouses',
      description: 'Acesso de edição aos Warehouses',
    },
    warehouseViewer: {
      label: 'Visualizador de Warehouses',
      description: 'Acesso de visualização aos Warehouses',
    },
    buildingEditor: {
      label: 'Editor de Construções',
      description: 'Acesso de edição aos Construções',
    },
    buildingViewer: {
      label: 'Visualizador de Construções',
      description: 'Acesso de visualização aos Construções',
    },
    buildingLevel1Editor: {
      label: 'Editor de Nível 1',
      description: 'Acesso de edição aos Nível 1',
    },
    buildingLevel1Viewer: {
      label: 'Visualizador de Nível 1',
      description: 'Acesso de visualização aos Nível 1',
    },
    buildingLevel2Editor: {
      label: 'Editor de Nível 2',
      description: 'Acesso de edição aos Nível 2',
    },
    buildingLevel2Viewer: {
      label: 'Visualizador de Nível 2',
      description: 'Acesso de visualização aos Nível 2',
    },
    operator: {
      label: 'Operador',
      description: 'Acesso de visualização operador',
    },
    operatorAdmin: {
      label: 'Operador adiministrador',
      desciption: 'Acesso de edição aos operadores',
    }
  },

  permissions: {
    iamEdit: {
      label: 'iamEdit',
      description: ''
    },
    iamCreate: {
      label: 'iamCreate',
      description: ''
    },
    iamImport: {
      label: 'iamImport',
      description: ''
    },
    iamRead: {
      label: 'iamRead',
      description: ''
    },
    iamUserAutocomplete: {
      label: 'iamUserAutocomplete',
      description: ''
    },
    auditLogRead: {
      label: 'auditLogRead',
      description: ''
    },
    settingsEdit: {
      label: 'settingsEdit',
      description: ''
    },
    customerImport: {
      label: 'customerImport',
      description: ''
    },
    customerCreate: {
      label: 'customerCreate',
      description: ''
    },
    customerEdit: {
      label: 'customerEdit',
      description: ''
    },
    customerDestroy: {
      label: 'customerDestroy',
      description: ''
    },
    customerRead: {
      label: 'customerRead',
      description: ''
    },
    customerAutocomplete: {
      label: 'customerAutocomplete',
      description: ''
    },
    productImport: {
      label: 'productImport',
      description: ''
    },
    productCreate: {
      label: 'productCreate',
      description: ''
    },
    productEdit: {
      label: 'productEdit',
      description: ''
    },
    productDestroy: {
      label: 'productDestroy',
      description: ''
    },
    productRead: {
      label: 'productRead',
      description: ''
    },
    productAutocomplete: {
      label: 'productAutocomplete',
      description: ''
    },
    paymentCreate: {
      label: 'paymentCreate',
      description: ''
    },
    paymentEdit: {
      label: 'paymentEdit',
      description: ''
    },
    paymentDestroy: {
      label: 'paymentDestroy',
      description: ''
    },
    paymentRead: {
      label: 'paymentRead',
      description: ''
    },
    orderImport: {
      label: 'orderImport',
      description: ''
    },
    orderCreate: {
      label: 'orderCreate',
      description: ''
    },
    orderEdit: {
      label: 'orderEdit',
      description: ''
    },
    orderResendSMS: {
      label: 'orderResendSMS',
      description: ''
    },
    orderUpdateOverdue: {
      label: 'orderUpdateOverdue',
      description: ''
    },
    orderUpdateStatus: {
      label: 'orderUpdateStatus',
      description: ''
    },
    orderDestroy: {
      label: 'orderDestroy',
      description: ''
    },
    orderRead: {
      label: 'orderRead',
      description: ''
    },
    orderQrcode: {
      label: 'orderQrcode',
      description: ''
    },
    orderAutocomplete: {
      label: 'orderAutocomplete',
      description: ''
    },
    orderExport: {
      label: 'orderExport',
      description: ''
    },
    orderInbound: {
      label: 'orderInbound',
      description: ''
    },
    generateTag: {
      label: 'generateTag',
      description: ''
    },
    companyImport: {
      label: 'companyImport',
      description: ''
    },
    companyCreate: {
      label: 'companyCreate',
      description: ''
    },
    companyEdit: {
      label: 'companyEdit',
      description: ''
    },
    companyDestroy: {
      label: 'companyDestroy',
      description: ''
    },
    companyRead: {
      label: 'companyRead',
      description: ''
    },
    companyAutocomplete: {
      label: 'companyAutocomplete',
      description: ''
    },
    companyUserCreate: {
      label: 'companyUserCreate',
      description: ''
    },
    roleCreate: {
      label: 'roleCreate',
      description: ''
    },
    roleEdit: {
      label: 'roleEdit',
      description: ''
    },
    roleDestroy: {
      label: 'roleDestroy',
      description: ''
    },
    roleRead: {
      label: 'roleRead',
      description: ''
    },
    roleAutocomplete: {
      label: 'roleAutocomplete',
      description: ''
    },
    roleUserCreate: {
      label: 'roleUserCreate',
      description: ''
    },
    eboxImport: {
      label: 'eboxImport',
      description: ''
    },
    eboxCreate: {
      label: 'eboxCreate',
      description: ''
    },
    eboxEdit: {
      label: 'eboxEdit',
      description: ''
    },
    eboxDestroy: {
      label: 'eboxDestroy',
      description: ''
    },
    eboxRead: {
      label: 'eboxRead',
      description: ''
    },
    eboxAutocomplete: {
      label: 'eboxAutocomplete',
      description: ''
    },
    clusterImport: {
      label: 'clusterImport',
      description: ''
    },
    clusterCreate: {
      label: 'clusterCreate',
      description: ''
    },
    clusterEdit: {
      label: 'clusterEdit',
      description: ''
    },
    clusterDestroy: {
      label: 'clusterDestroy',
      description: ''
    },
    clusterRead: {
      label: 'clusterRead',
      description: ''
    },
    clusterAutocomplete: {
      label: 'clusterAutocomplete',
      description: ''
    },
    eboxModuleImport: {
      label: 'eboxModuleImport',
      description: ''
    },
    eboxModuleCreate: {
      label: 'eboxModuleCreate',
      description: ''
    },
    eboxModuleEdit: {
      label: 'eboxModuleEdit',
      description: ''
    },
    eboxModuleDestroy: {
      label: 'eboxModuleDestroy',
      description: ''
    },
    eboxModuleRead: {
      label: 'eboxModuleRead',
      description: ''
    },
    eboxModuleAutocomplete: {
      label: 'eboxModuleAutocomplete',
      description: ''
    },
    eboxReboot: {
      label: 'Reinicia E-Box',
      description: ''
    },
    eboxRestartApp: {
      label: 'Reinicia Aplicativo',
      description: ''
    },
    eboxSync: {
      label: 'Sincroniza E-Box',
      description: ''
    },
    compartimentLayoutImport: {
      label: 'compartimentLayoutImport',
      description: ''
    },
    compartimentLayoutCreate: {
      label: 'compartimentLayoutCreate',
      description: ''
    },
    compartimentLayoutEdit: {
      label: 'compartimentLayoutEdit',
      description: ''
    },
    compartimentLayoutDestroy: {
      label: 'compartimentLayoutDestroy',
      description: ''
    },
    compartimentLayoutRead: {
      label: 'compartimentLayoutRead',
      description: ''
    },
    compartimentLayoutAutocomplete: {
      label: 'compartimentLayoutAutocomplete',
      description: ''
    },
    groupImport: {
      label: 'groupImport',
      description: ''
    },
    groupCreate: {
      label: 'groupCreate',
      description: ''
    },
    groupEdit: {
      label: 'groupEdit',
      description: ''
    },
    groupDestroy: {
      label: 'groupDestroy',
      description: ''
    },
    groupRead: {
      label: 'groupRead',
      description: ''
    },
    groupAutocomplete: {
      label: 'groupAutocomplete',
      description: ''
    },
    packingCreate: {
      label: 'packingCreate',
      description: ''
    },
    packingDestroy: {
      label: 'packingDestroy',
      description: ''
    },
    packingRead: {
      label: 'packingRead',
      description: ''
    },
    packingAutocomplete: {
      label: 'packingAutocomplete',
      description: ''
    },
    compartimentImport: {
      label: 'compartimentImport',
      description: ''
    },
    compartimentCreate: {
      label: 'compartimentCreate',
      description: ''
    },
    compartimentEdit: {
      label: 'compartimentEdit',
      description: ''
    },
    compartimentDestroy: {
      label: 'compartimentDestroy',
      description: ''
    },
    compartimentRead: {
      label: 'compartimentRead',
      description: ''
    },
    compartimentAutocomplete: {
      label: 'compartimentAutocomplete',
      description: ''
    },
    compartimentOpen: {
      label: 'Abrir Compartimento',
      description: ''
    },
    compartimentLock: {
      label: 'Trancar Compartimento',
      description: ''
    },
    webhookImport: {
      label: 'webhookImport',
      description: ''
    },
    webhookCreate: {
      label: 'webhookCreate',
      description: ''
    },
    webhookEdit: {
      label: 'webhookEdit',
      description: ''
    },
    webhookDestroy: {
      label: 'webhookDestroy',
      description: ''
    },
    webhookRead: {
      label: 'webhookRead',
      description: ''
    },
    webhookAutocomplete: {
      label: 'webhookAutocomplete',
      description: ''
    },
    ccmImport: {
      label: 'ccmImport',
      description: ''
    },
    ccmCreate: {
      label: 'ccmCreate',
      description: ''
    },
    ccmEdit: {
      label: 'ccmEdit',
      description: ''
    },
    ccmDestroy: {
      label: 'ccmDestroy',
      description: ''
    },
    ccmRead: {
      label: 'ccmRead',
      description: ''
    },
    ccmAutocomplete: {
      label: 'ccmAutocomplete',
      description: ''
    },
    qrcodeTemplateImport: {
      label: 'qrcodeTemplateImport',
      description: ''
    },
    qrcodeTemplateCreate: {
      label: 'qrcodeTemplateCreate',
      description: ''
    },
    qrcodeTemplateEdit: {
      label: 'qrcodeTemplateEdit',
      description: ''
    },
    qrcodeTemplateDestroy: {
      label: 'qrcodeTemplateDestroy',
      description: ''
    },
    qrcodeTemplateRead: {
      label: 'qrcodeTemplateRead',
      description: ''
    },
    qrcodeTemplateAutocomplete: {
      label: 'qrcodeTemplateAutocomplete',
      description: ''
    },
    warehouseImport: {
      label: 'warehouseImport',
      description: ''
    },
    warehouseCreate: {
      label: 'warehouseCreate',
      description: ''
    },
    warehouseEdit: {
      label: 'warehouseEdit',
      description: ''
    },
    warehouseDestroy: {
      label: 'warehouseDestroy',
      description: ''
    },
    warehouseRead: {
      label: 'warehouseRead',
      description: ''
    },
    warehouseAutocomplete: {
      label: 'warehouseAutocomplete',
      description: ''
    },
    buildingImport: {
      label: 'buildingImport',
      description: ''
    },
    buildingCreate: {
      label: 'buildingCreate',
      description: ''
    },
    buildingEdit: {
      label: 'buildingEdit',
      description: ''
    },
    buildingDestroy: {
      label: 'buildingDestroy',
      description: ''
    },
    buildingRead: {
      label: 'buildingRead',
      description: ''
    },
    buildingAutocomplete: {
      label: 'buildingAutocomplete',
      description: ''
    },
    buildingLevel1Import: {
      label: 'buildingLevel1Import',
      description: ''
    },
    buildingLevel1Create: {
      label: 'buildingLevel1Create',
      description: ''
    },
    buildingLevel1Edit: {
      label: 'buildingLevel1Edit',
      description: ''
    },
    buildingLevel1Destroy: {
      label: 'buildingLevel1Destroy',
      description: ''
    },
    buildingLevel1Read: {
      label: 'buildingLevel1Read',
      description: ''
    },
    buildingLevel1Autocomplete: {
      label: 'buildingLevel1Autocomplete',
      description: ''
    },
    buildingLevel2Import: {
      label: 'buildingLevel2Import',
      description: ''
    },
    buildingLevel2Create: {
      label: 'buildingLevel2Create',
      description: ''
    },
    buildingLevel2Edit: {
      label: 'buildingLevel2Edit',
      description: ''
    },
    buildingLevel2Destroy: {
      label: 'buildingLevel2Destroy',
      description: ''
    },
    buildingLevel2Read: {
      label: 'buildingLevel2Read',
      description: ''
    },
    buildingLevel2Autocomplete: {
      label: 'buildingLevel2Autocomplete',
      description: ''
    },
    terminalOrderExpiredCollect: {
      label: 'terminalOrderExpiredCollect',
      description: ''
    },
    terminalOrderReturnedCollect: {
      label: 'terminalOrderReturnedCollect',
      description: ''
    },
    terminalOrderStore: {
      label: 'terminalOrderStore',
      description: ''
    },
    terminalCompartimentRead: {
      label: 'terminalCompartimentRead',
      description: ''
    },
    terminalCompartimentEdit: {
      label: 'terminalCompartimentEdit',
      description: ''
    },
    terminalUiReleaseCreate: {
      label: 'terminalUiReleaseCreate',
      description: ''
    },
    terminalUiReleaseEdit: {
      label: 'terminalUiReleaseEdit',
      description: ''
    },
    terminalUiReleaseDestroy: {
      label: 'terminalUiReleaseDestroy',
      description: ''
    },
    terminalUiReleaseRead: {
      label: 'terminalUiReleaseRead',
      description: ''
    },
    terminalUiReleaseAutocomplete: {
      label: 'terminalUiReleaseAutocomplete',
      description: ''
    },
    terminalUiVersionCreate: {
      label: 'terminalUiVersionCreate',
      description: ''
    },
    terminalUiVersionEdit: {
      label: 'terminalUiVersionEdit',
      description: ''
    },
    terminalUiVersionDestroy: {
      label: 'terminalUiVersionDestroy',
      description: ''
    },
    terminalUiVersionRead: {
      label: 'terminalUiVersionRead',
      description: ''
    },
    terminalUiVersionAutocomplete: {
      label: 'terminalUiVersionAutocomplete',
      description: ''
    },
    filterEbox: {
      label: 'filterEbox',
      description: ''
    },
    filterCluster: {
      label: 'filterCluster',
      description: ''
    },
    filterType: {
      label: 'filterType',
      description: ''
    },
    filterReferenceNumber: {
      label: 'filterReferenceNumber',
      description: ''
    },
    uploadFiles: {
      label: 'uploadFiles',
      description: ''
    },
    terminalCollectPending: {
      label: 'terminalCollectPending',
      description: ''
    },
    terminalShowEboxStatus: {
      label: 'terminalShowEboxStatus',
      description: ''
    },
    terminalOperatorCondominium: {
      label: 'terminalOperatorCondominium',
      description: ''
    },
    terminalShowPendingDepositOperator: {
      label: 'terminalShowPendingDepositOperator',
      description: ''
    },
    terminalMaintenance: {
      label: 'terminalMaintenance',
      description: ''
    },
    operationAutocomplete: {
      label: 'operationAutocomplete',
      description: ''
    },
    operationCreate: {
      label: 'operationCreate',
      description: ''
    },
    operationEdit: {
      label: 'operationEdit',
      description: ''
    },
    operationDestroy: {
      label: 'operationDestroy',
      description: ''
    },
    operationRead: {
      label: 'operationRead',
      description: ''
    },
    paymentGatewaysAutocomplete: {
      label: 'paymentGatewaysAutocomplete',
      description: ''
    },
    paymentGatewaysCreate: {
      label: 'paymentGatewaysCreate',
      description: ''
    },
    paymentGatewaysEdit: {
      label: 'paymentGatewaysEdit',
      description: ''
    },
    paymentGatewaysDestroy: {
      label: 'paymentGatewaysDestroy',
      description: ''
    },
    paymentGatewaysRead: {
      label: 'paymentGatewaysRead',
      description: ''
    },
  },

  iam: {
    title: 'Gerenciamento de usuários e permissões',
    menu: 'IAM',
    disable: 'Desabilitar',
    disabled: 'Desabilitado',
    enabled: 'Habilitado',
    enable: 'Habilitar',
    doEnableSuccess: 'Usuário habilitado com sucesso',
    doDisableSuccess: 'Usuário desabilitado com sucesso',
    doDisableAllSuccess:
      'Usuário(s) desabilitado(s) com sucesso',
    doEnableAllSuccess:
      'Usuário(s) habilidatos com sucesso',
    doAddSuccess: 'Usuário(s) salvos com sucesso',
    doUpdateSuccess: 'Usuário salvo com sucesso',
    viewBy: 'Visualizar por',
    confirmPassword: 'Confirme a nova senha',
    typePassword: 'Por favor, digite a senha',
    password: 'Digite a nova senha',
    oldPassword: 'Digite a senha atual',
    sendPassword: 'Trocar senha',
    users: {
      name: 'users',
      label: 'Usuários',
      exporterFileName: 'usuarios_exportados',
      doRemoveAllSelectedSuccess:
        'Permissões removidas com sucesso',
    },
    roles: {
      label: 'Perfis',
      doRemoveAllSelectedSuccess:
        'Permissões removidas com sucesso',
    },
    edit: {
      title: 'Editar usuário',
    },
    new: {
      title: 'Novo(s) Usuário(s)',
      titleModal: 'Novo Usuário',
      emailsHint:
        'Separe múltiplos endereços de e-mail usando a vírgula.',
    },
    view: {
      title: 'Visualizar Usuário',
      activity: 'Atividades',
    },
    importer: {
      title: 'Importar Usuários',
      fileName: 'usuarios_template_importacao',
      hint:
        'Arquivos/Imagens devem ser as URLs dos arquivos, separados por espaço. Relacionamentos devem ser os IDs separados por espaço.',
    },
    errors: {
      userAlreadyExists: 'Usuário com este email já existe',
      userNotFound: 'Usuário não encontrado',
      disablingHimself: `Você não pode desativar-se`,
      revokingOwnPermission: `Você não pode revogar sua própria permissão de proprietário`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Id de autenticação',
      avatars: 'Avatar',
      email: 'Email',
      fieldResetPasword: 'Usuário ou email',
      allowedCompanies: 'Limitar acesso as empresas',
      emails: 'Email(s)',
      fullName: 'Nome',
      firstName: 'Nome',
      lastName: 'Sobrenome',
      username: 'Username',
      status: 'Estado',
      disabled: 'Desativado',
      phoneNumber: 'Telefone',
      role: 'Perfil',
      createdAt: 'Criado em',
      updatedAt: 'Atualizado em',
      roleUser: 'Perfil/Usuário',
      roles: 'Perfis',
      createdAtRange: 'Criado em',
      password: 'Senha',
      rememberMe: 'Lembrar-me',
      company: 'Empresa',
      useTemporaryToken: 'Usar token temporário',
    },
    enabled: 'Habilitado',
    disabled: 'Desabilitado',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} é inválido',
    },
  },

  auditLog: {
    menu: 'Registros de Auditoria',
    title: 'Registros de Auditoria',
    exporterFileName: 'registros_autoria_exportados',
    entityNamesHint:
      'Separe múltiplas entidades por vírgula',
    fields: {
      id: 'Id',
      timestampRange: 'Período',
      entityName: 'Entidade',
      entityNames: 'Entidades',
      entityId: 'ID da Entidade',
      action: 'Ação',
      values: 'Valores',
      timestamp: 'Data',
      createdByEmail: 'Email do Usuário',
    },
  },
  settings: {
    title: 'Configurações',
    menu: 'Configurações',
    save: {
      success:
        'Configurações salvas com sucesso. A página irá recarregar em {0} para que as alterações tenham efeito.',
    },
    fields: {
      theme: 'Tema',
    },
    colors: {
      default: 'Padrão',
      cyan: 'Ciano',
      'geek-blue': 'Azul escuro',
      gold: 'Ouro',
      lime: 'Limão',
      magenta: 'Magenta',
      orange: 'Laranja',
      'polar-green': 'Verde polar',
      purple: 'Roxo',
      red: 'Vermelho',
      volcano: 'Vúlcão',
      yellow: 'Amarelo',
    },
  },
  home: {
    menu: 'Dashboard',
    message: `Esta página usa dados falsos apenas para fins de demonstração. Você pode editá-la em frontend/view/home/HomePage.js.`,
    charts: {
      day: 'Dia',
      red: 'Vermelho',
      green: 'Verde',
      yellow: 'Amarelho',
      grey: 'Cinza',
      blue: 'Azul',
      orange: 'Laranja',
      months: {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
      },
      eating: 'Comendo',
      drinking: 'Bebendo',
      sleeping: 'Dormindo',
      designing: 'Projetando',
      coding: 'Codificando',
      cycling: 'Pedalando',
      running: 'Correndo',
      customer: 'Cliente',
    },
    fields: {
      company: 'Empresa/Filial',
      data_relativa: 'Período Consulta'
    },
    enumerators: {
      data_relativa: {
        today: 'Hoje',
        yesterday: 'Ontem',
        past7days: 'Últimos 7 dias',
        past30days: 'Últimos 30 dias',
        lastweek: 'Última semana',
        lastmonth: 'Último mês',
        lastyear: 'Último ano',
        thisweek: 'Esta semana',
        thismonth: 'Este mês',
        thisyear: 'Este ano',
      },
    },
  },
  errors: {
    backToHome: 'Voltar a página inicial',
    403: `Desculpe, você não tem acesso a esta página`,
    404: 'Desculpe, a página que você visitou não existe',
    500: 'Desculpe, o servidor está relatando um erro',
    forbidden: {
      message: 'Acesso negado',
    },
    validation: {
      message: 'Ocorreu um erro',
    },
    defaultErrorMessage: 'Ops, ocorreu um erro',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} é inválido',
      required: '${path} é obrigatório',
      oneOf:
        '${path} deve ser um dos seguintes valores: ${values}',
      notOneOf:
        '${path} não deve ser um dos seguintes valores: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} deve ser um ${type}`;
      },
    },
    string: {
      length: '${path} deve possuir ${length} caracteres',
      min:
        '${path} deve possuir ao menos ${min} caracteres',
      max:
        '${path} deve possui no máximo ${max} caracteres',
      matches:
        '${path} deve respeitar o padrão: "${regex}"',
      email: '${path} deve ser um email válido',
      url: '${path} deve ser uma URL válida',
      trim:
        '${path} deve ser uma palavra sem espaços em branco',
      lowercase: '${path} deve ser minúsculo',
      uppercase: '${path} deve ser maiúsculo',
      selected: '${path} deve ser selecionado',
    },
    number: {
      min: '${path} deve ser maior ou igual a ${min}',
      max: '${path} deve ser menor ou igual a ${max}',
      lessThan: '${path} deve ser menor que ${less}',
      moreThan: '${path} deve ser maior que ${more}',
      notEqual: '${path} não deve ser igual a ${notEqual}',
      positive: '${path} deve ser um número positivo',
      negative: '${path} deve ser um número negativo',
      integer: '${path} deve ser um inteiro',
    },
    date: {
      min: '${path} deve ser posterior a ${min}',
      max: '${path} deve ser mais cedo do que ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} não pode ter atributos não especificados no formato do objeto',
    },
    array: {
      min: '${path} deve possuir ao menos ${min} itens',
      max: '${path} deve possuir no máximo ${max} itens',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'Você deve fazer upload de uma imagem',
    size:
      'O arquivo é muito grande. O tamanho máximo permitido é {0}',
    formats: `Formato inválido. Deve ser: '{0}'.`,
  },
  importer: {
    line: 'Linha',
    status: 'Estado',
    pending: 'Pendente',
    imported: 'Importado',
    error: 'Erro',
    total: `{0} importado, {1} pendente e {2} com erro`,
    importedMessage: `Processados {0} de {1}.`,
    noNavigateAwayMessage:
      'Não saia desta página ou a importação será interrompida.',
    completed: {
      success:
        'Importação concluída. Todas as linhas foram importadas com sucesso.',
      someErrors:
        'O processamento foi concluído, mas algumas linhas não puderam ser importadas.',
      allErrors:
        'Importação falhou. Não há linhas válidas.',
    },
    form: {
      downloadTemplate: 'Baixe o modelo',
      hint:
        'Clique ou arraste o arquivo para esta área para continuar.',
    },
    list: {
      discardConfirm:
        'Você tem certeza? Dados não importados serão perdidos.',
    },
    errors: {
      invalidFileEmpty: 'O arquivo está vazio',
      invalidFileExcel:
        'Apenas arquivos Excel (.xlsx) são permitidos',
      invalidFileUpload:
        'Arquivo inválido. Verifique se você está usando a última versão do modelo.',
      importHashRequired: 'Hash de importação é necessário',
      importHashExistent: 'Dados já foram importados',
    },
  },

  autocomplete: {
    loading: 'Carregando...',
  },

  imagesViewer: {
    noImage: 'Sem imagem',
  },

  notification: {
    send: 'Enviar Comunicação',
    resend: 'Reenviar Comunicação',
    success: 'Comunicação enviada com sucesso!',
    error: 'Ocorreu um erro ao enviar as comunicações',
  },

  openport: {
    success: 'Comando de abertura enviado com sucesso.',
    error: 'Não foi possível obter comunicação com o locker.',
  },

  orderExport: {
    success: 'Será encaminhado ao seu e-mail um link para download dos dados assim que o processo de exportação for concluído.',
    error: 'Não foi possível exportar os pedidos.',
  },

  iamExport: {
    success: 'Será encaminhado ao seu e-mail um link para download dos dados assim que o processo de exportação for concluído.',
    error: 'Não foi possível exportar os dados.',
  },

  subMenu: {
    locations: 'Locais',
    terminal: 'Terminal',
    system: 'Sistema'
  }
};

export default ptBR;
